import React, { useEffect } from "react";
import PageBanner from "../Components/PageBanner";

const Agencies = () => {
  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);
  return (
    <div className="ProfileSection">
      <PageBanner title="Agencias" />

      <div className="canvas mx-auto">
        <h1 className="fw-bold mt-5">Agencias De Entrega</h1>
        <div className="accordion mt-4" id="accordionExample">
          <div className="accordion-item rounded border-0">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                Gran Caracas
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse "
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                <div className="d-flex align-items-start mt-3 fs-6">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    ALTAMIRA:
                  </b>{" "}
                  Av. San Juan Bosco de Altamira con 1era. Transversal, Res.
                  Excelsior, PB, Local 2.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    ANDRÉS BELLO:
                  </b>{" "}
                  Av. Andrés Bello, Colegio Nacional de Periodista, Local 4PB
                  (Frente a Pollos Rivera) CP 1050
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    ANTÍMANO/LA YAGUARA:
                  </b>{" "}
                  Calle Real Boulevard de Antímano, entre Jefatura Civil y Plaza
                  Bolívar, Local MRW.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    AV. CASANOVA:
                  </b>{" "}
                  Inicio Av. Casanova, entre Calle San Antonio y Calle El
                  Colegio, C.C. del Este, N. 21 (Diagonal a Hotel Kurssal, a
                  media cuadra del Arabito de Sabana Grande).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {" "}
                    BARUTA:
                  </b>{" "}
                  Calle Negro Primero cruce con Sucre, N. 15-04 (Al lado de
                  Eurosweaters).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    BELLO MONTE:
                  </b>{" "}
                  Colinas de Bello Monte, Av. Miguelangel, Edif. Oberon, Local E
                  (Frente a Pastelería La Sabrina) CP 1060
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {" "}
                    CAMPO CLARO:
                  </b>{" "}
                  Av. Francisco de Miranda con Calle Guanche, Edif. Valencia I,
                  PB (Al lado del Banco BOD) Los Dos Caminos.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    CATIA:
                  </b>{" "}
                  Av. Sucre, C.C. Oeste, Nivel 3, Local 038.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    CARICUAO:
                  </b>{" "}
                  Redoma de Ruiz Pineda, C.C. Caricuao Plaza, Piso 2, Local 127.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    CAPITOLIO:
                  </b>{" "}
                  Av. Baralt, esquina de Conde a Piñango, Casona N. 11 (Al lado
                  de Jefatura Catedral).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    CENTRO:
                  </b>{" "}
                  Av. Universidad, esquina de Sociedad a Gradillas, Edif.
                  Humbolt, PB, Local 4.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    CHUAO:
                  </b>{" "}
                  Av. Ernesto Blon, C.C. Ciudad Tamanaco, PB, Local F.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    CHACAITO:
                  </b>{" "}
                  Av. Pichincha, entre salida de Metro y Av. Tamanaco, C.C.
                  Unico, PB, Local 5 y 6, Urb. El Rosal.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    CHACAO:
                  </b>{" "}
                  Calle El Muñeco, entre Av. Francisco de Miranda y Av.
                  Libertador, Edif. Guan, PB, Local 7.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    EL LLANITO:
                  </b>{" "}
                  Av. Ppal del Llanito, Ferretería Casatrigo (Al frente de
                  Supermercado Don Sancho).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    EL PARAISO:
                  </b>{" "}
                  Entre A. Las Fuentes y Av. Washington, Calle 1, Quinta
                  Francelis, PB, Local 1.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    EL HATILLO:
                  </b>{" "}
                  Calle Matadero, C.C. Priscos, PB, Ofic. 904-4.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    EL CEMENTERIO:
                  </b>{" "}
                  Av. Los Totumos entre Av. Louis Braillet y Floresta, Casa 20,
                  Prado de María.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    EL JUNQUITO:
                  </b>{" "}
                  C.C. Casa Junko, PB, Local A7, Km 18, Antigua Bomba.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    EL BOSQUE:
                  </b>{" "}
                  Calle El Carmen, entre Av. El Empalme y Ppal. del Bosque,
                  Quinta Torre La Vega, PB, Local 5 (A media cuadra de
                  Fedecamaras).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    EL VALLE:
                  </b>{" "}
                  Av. Intercomunal del Valle, C.C. El Palmar, Res. Don Pedro,
                  Torre F, PB, Local MRW.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    FUERTE TIUNA:
                  </b>{" "}
                  Av. Las Secuelas, Centro Simón Bolívar (Ferente al Stadium
                  Jose Antonio Casanova.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LAS ACACIAS:
                  </b>{" "}
                  Av. General Medina Angarita, Edif. Res. Betania, PB, Local
                  MRW, Urb. Las Acacias (Frente a Repuestos Pamaca).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LA CANDELARIA:
                  </b>{" "}
                  Perico a Puente Yanez, Edif. Serrano, PB, Local 3.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LA CASTELLANA:
                  </b>{" "}
                  Calle Urdaneta con Ppal. de La Castellana, Quinta Aurana, PB
                  (A media cuadra del Restaurante La Estancia).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LA FLORIDA:
                  </b>{" "}
                  Urb. La Florida, Av. Los Chaguaramos, Quinta Coroli.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LAS MERCEDES:
                  </b>{" "}
                  Av. Veracruz, Edif. Matisco, PB (Diagonal a CONATEL).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LA URBINA:
                  </b>{" "}
                  Av. Ppal. de La Urbina, Edif. Apulia, PB, Local 4 (Cerca de
                  Panadería Tulipán).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LA TRINIDAD:
                  </b>{" "}
                  Calle del Arenal, Quinta Laura, Urb. La Trinidad (A dos
                  cuadras de Farmatodo, frente a Clínica Veterinaria Ávila).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LAS MINAS:
                  </b>{" "}
                  Av. Ppal. Las Minas de Baruta, C.C. Luna, Local 15 (Frente a
                  parada de las camionetas al Silencio, a 50mts de la Policía
                  Fátima).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LEBRUN PETARE:
                  </b>{" "}
                  Calle Los Naranjos, Edif. Lebrun, PB, Local 19 (Frente a Liceo
                  Mariano Picón Salas).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LECUNA:
                  </b>{" "}
                  Calle Sur 3, Esquina Zamuro a Miseria, Edif. Inducentro, PB,
                  Local 1 (A 20mts de la Av. Lecuna) Santa Rosalía.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LOS CAOBOS:
                  </b>{" "}
                  Av. Panamá con Av. Libertador, Quinta MRW (Detrás de
                  Restaurante Gran Yein, subiendo por la Torre Polar).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LOS CHAGUARAMOS:
                  </b>{" "}
                  Av. Universidad, Edif. Maurica, PB, Local G (Frente a la
                  Iglesia San Pedro).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LOS CHORROS:
                  </b>{" "}
                  Av. Ppal. La Carlota, entre Av. Francisco de Miranda y Rómulo
                  Gallegos, Edif. Bertha, Local 05 (Frente a zona de carga del
                  Millenium).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LOS PALOS GRANDES:
                  </b>{" "}
                  Av. Andrés Bello de Los Palos Grandes con 2da Transversal,
                  Edif. Vista Hermosa, PB, Local C.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    LOS ROSALES:
                  </b>{" "}
                  Av. Los Laureles con Av. Roosevelt, Res. Tiuna, Local E, PB
                  (Frente a Plaza Tiuna Los Rosales).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    PALO VERDE:
                  </b>{" "}
                  C.C. Palo Verde, Nivel 2, Local 11-2 (Frente a Farmacia
                  Naturista, dentro de Centro de Comunicaciones Movilnet CANTV).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    PANTIN:
                  </b>{" "}
                  Calle Pantin, entre Calle Samán y Los Angeles, Galpón MRW
                  (Frente a Policía de Chacao).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    PLAZA ESTRELLA:
                  </b>{" "}
                  Plaza Estrella a San Felipe, Res. Dorabel, PB, Local 3 (A
                  media cuadra de Pasaje Anauco) San Bernardino.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    PLAZA LAS AMÉRICAS:
                  </b>{" "}
                  C.C. Plaza Las Américas, Nivel Oro, Local 107-A, El Cafetal.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    SABANA GRANDE:
                  </b>{" "}
                  Av. Orinoco, entre 2da Calle y Caroní, Quinta Ismenia, Local
                  PB.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    SANTA FE:
                  </b>{" "}
                  Urb. Santa Fe Norte, Av. José María Vargas, C.C. Santa Fe,
                  Piso 2, Nivel Alameda, Local C2-43 (Al lado de Churromanía).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    SAN MARTÍN:
                  </b>{" "}
                  Av. San Martín cruce con Av. Santander, C.C. Maracaibo, Nivel
                  Mezanina, Local 12.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    SANTA MONICA:
                  </b>{" "}
                  Av. Arturo Michelena cruce con Agustín Codazzi, Quinta Ceiba
                  (Al lado de Farmacia Bama).
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    SANTA SOFÍA:
                  </b>{" "}
                  Av. Ppal. Santa Sofía, C.C. Santa Sofía, Local Z-3, PB.
                </div>
                <div className="d-flex align-items-start mt-3">
                  <b
                    className="text-dark me-3"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    SEBUCAN:
                  </b>{" "}
                  Av. Rómulo Gallegos entre Av. Ppal. de Sebucan y Av. Sucre de
                  Los Dos Caminos, Res. Carlo, Local 2.
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionTwo">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                >
                  Amazonas
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse "
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3"> PUERTO AYACUCHO:</b> Av. Ppal.
                  de Urb. Andrés Eloy Blanco, Edif. Bellmar, Local 1.
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionThree">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                >
                  Anzoátegui
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse "
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3"> BARCELONA:</b> Av. Fuerzas
                  Armadas, esquina Calle Eulalia Buroz, Edif. Azgan, PB, Local
                  02, Sector Centro.
                  <br />
                  <br />
                  <b className="text-dark me-3">ANACO:</b> Av. José Antonio
                  Anzoátegui, C.C. Anaco Center, Local 79-C.
                  <br />
                  <br />
                  <b className="text-dark me-3"> EL TIGRE:</b> Av. Francisco de
                  Miranda con Calle 18 Sur, Edif. Los Geranios 2 y 3 (Frente a
                  Panadería Siria).
                  <br />
                  <br />
                  <b className="text-dark me-3"> LAS GARZAS:</b> Av. Jorge
                  Rodríguez, Local MRW 1, Sector Las Garzas Lechería.
                  <br />
                  <br />
                  <b className="text-dark me-3"> PUERTO LA CRUZ:</b> Av.
                  Municipal, C.C. Estación de Servicio Sandoval, PB, Local
                  137-A, Urb. Pueblo Nuevo.
                  <br />
                  <br />
                  <b className="text-dark me-3"> PARIAGUAN:</b> Calle Bolívar
                  cruce con Colombia, Sector Centro (Frente a Cooperativa de
                  Transporte Pariaguan Express, a pocos mts de Farmacia).
                  <br />
                  <br />
                  <b className="text-dark me-3"> PUERTO LA CRUZ CENTRO:</b>{" "}
                  Calle Ricauter, Edif. D Jorge, PB, Local 1.
                  <br />
                  <br />
                  <b className="text-dark me-3"> NUEVA BARCELONA:</b> Prolong.
                  Av. Fuerzas Armadas, C.C. Los Chaguaramos, Local 4.
                  <br />
                  <br />
                  <b className="text-dark me-3"> CLARINES:</b> Av. Fernández
                  Padilla, Mini C.C Los Cocos, PB, Local 5.
                  <br />
                  <br />
                  <b className="text-dark me-3"> LECHERÍA:</b> Av. Ppal. de
                  Lechería, C.C. La Concha, PB, Local 7 (Al lado de La
                  Bodeguilla).
                  <br />
                  <br />
                  <b className="text-dark me-3"> EL TIGRE CENTRO:</b> Carrera 2
                  Sur entre Calle 2 y 3, Edif. Hana, Pico 1, Local 1, Sector
                  Pueblo Nuevo Sur.
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionFour">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                >
                  Apure
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse "
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3"> GUASDUALITO:</b> Calle Cedeño
                  entre Carrera Arismendi y General Salón, Casa S/N, Sector
                  Centro Guasdualito.
                  <br />
                  <br />
                  <b className="text-dark me-3">SAN FERNANDO CENTRO:</b> Av.
                  Caracas, Centro Empresarial Genesis, PB, Local 1 (A 50 mts de
                  Paseo Libertador).
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionFive">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="true"
                  aria-controls="collapseFive"
                >
                  Aragua
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3"> MARACAY ZONA INDUSTRIAL:</b>Av.
                  Bermudez, C.C. Maracay Plaza, PB, Local PB-82F.
                  <br />
                  <br />
                  <b className="text-dark me-3">LA VICTORIA:</b>Av. Victoria,
                  PB, Local 2 (Frente al Estadio Francisco de Miranda).
                  <br />
                  <br />
                  <b className="text-dark me-3"> MARACAY 5 DE JULIO:</b>Calle 5
                  de Julio entre Páez y Miranda, Edif. Santimone, PB, Local 3
                  (Diagonal a IMGEVE).
                  <br />
                  <br />
                  <b className="text-dark me-3">TURMERO:</b>Calle Mariño cruce
                  con Calle Peñalver, C.C. Mariño Plaza, PB, Local 2.
                  <br />
                  <br />
                  <b className="text-dark me-3">VILLA DE CURA:</b> Calle
                  Urdaneta Norte, Sector Centro.
                  <br />
                  <br />
                  <b className="text-dark me-3"> LAS TEJERÍAS:</b> Calle
                  Miranda, Local b-44 A 2, Casco Central (A dos cuadras de la
                  Plaza Bolívar, Municipio Santos Michelena).
                  <br />
                  <br />
                  <b className="text-dark me-3">
                    {" "}
                    SAN SEBASTIAN DE LOS REYES:
                  </b>{" "}
                  Calle Bolívar, Local 18-2, Sector Centro.
                  <b className="text-dark me-3"> MARACAY LA DEMOCRACIA:</b> Av.
                  Ayacucho Norte, N. 83, Barrio La Democracia.
                  <br />
                  <br />
                  <b className="text-dark me-3">
                    {" "}
                    TURMERO ZONA INDUSTRIAL:
                  </b>{" "}
                  Av. Intercomunal Maracay Turmero, C.C. Coche Aragua, Local 30
                  y 82, Sector La Morita.
                  <br />
                  <br />
                  <b className="text-dark me-3"> LA ENCRUCIJADA:</b> Av. Ppal.,
                  C.C. Los Laureles, PB, Local 12, Sector La Encrucijada de
                  Turmero.
                  <br />
                  <br />
                  <b className="text-dark me-3"> MARACAY SANTA ROSA:</b> Calle
                  Carabobo, N. 75-A, Sector Santa Rosa.
                  <br />
                  <br />
                  <b className="text-dark me-3"> MARACAY LA ROMANA:</b> Av.
                  Bolívar Oeste, N. 165, Edif. Guey 2, Calle Río Guey, Sector La
                  Romana.
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionSix">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingSix">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="true"
                  aria-controls="collapseSix"
                >
                  Barinas
                </button>
              </h2>
              <div
                id="collapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="headingSix"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3"> BARINAS:</b>Calle Cedeño, C.C.
                  Giamma, PB, Local 6, Sector Centro.
                  <br />
                  <br />
                  <b className="text-dark me-3">SOCOPÓ:</b>Calle 1 entre Carrera
                  6 y 7, Sector Barrio El Carmen (Diagonal a la Estrella
                  Oriental).
                  <br />
                  <br />
                  <b className="text-dark me-3"> BARINAS ZONA INDUSTRIAL:</b>Av.
                  Elías Cordero, Detrás del Terminal de Pasajeros, Edif. Hotel
                  Rey de Reyes, Local 03 (Diagonal a Ferretería El Mecatazo).
                  <br />
                  <br />
                  <b className="text-dark me-3">SANTA BARBARA DE BARINAS:</b>
                  Calle 12 entre Carreras 0 y 00, Sector El Progreso (A 2
                  cuadras y media de CORPOELEC).
                  <br />
                  <br />
                  <b className="text-dark me-3">BARINAS SABANETA:</b> Calle 1
                  entre Bayon y Obispo, C.C. Tricolor, N. 2, Sector 9 de
                  Diciembre de Sabaneta.
                  <br />
                  <br />
                  <b className="text-dark me-3"> LAS TEJERÍAS:</b> Calle
                  Miranda, Local b-44 A 2, Casco Central (A dos cuadras de la
                  Plaza Bolívar, Municipio Santos Michelena).
                  <br />
                  <br />
                  <b className="text-dark me-3"> ALTO BARINAS:</b> Av. Francia
                  entre Av. Pie de Monte y Táchira, Quinta Doña Ernestina, N.
                  135, Alto Barinas Norte.
                  <br />
                  <br />
                  <b className="text-dark me-3"> FORUM:</b> Av. 23 de Enero con
                  Av. Guaicaipuro, C.C. Forum, Local 52.
                  <br />
                  <br />
                  <b className="text-dark me-3"> BARINAS 23 DE ENERO:</b> Av. 23
                  de Enero, Edif. La Fortana, Local 3 (A 50 mts de la Residencia
                  de la Gobernación).
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionSeven">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingSeven">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="true"
                  aria-controls="collapseSeven"
                >
                  Bolívar
                </button>
              </h2>
              <div
                id="collapseSeven"
                className="accordion-collapse collapse"
                aria-labelledby="headingSeven"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3"> CIUDAD BOLÍVAR:</b>Av.
                  República, Edif. Franco, PB, Locales 1 y 2 (Al lado de
                  Banesco).
                  <br />
                  <br />
                  <b className="text-dark me-3">ALTA VISTA:</b>Av. Puerto Ordaz,
                  C.C: Babilonia Mall, PB, Local PB-30, Sector Alta Vista
                  (Bajando por el Banco Provincial).
                  <br />
                  <br />
                  <b className="text-dark me-3"> SANTA ELENA DE UAIREN:</b>Calle
                  Ikabarú, Troncal 10, Casco Central, Local S/N (A 100 mts del
                  Hospital Rosario Vera Zurita.
                  <br />
                  <br />
                  <b className="text-dark me-3">UNARE:</b>
                  Calle Neveri, Galpón MRW (Frente al Aeropuerto a 500 mt de los
                  Bomberos al lado de Metalmeg).
                  <br />
                  <br />
                  <b className="text-dark me-3">
                    CIUDAD BOLÍVAR ZONA INDUSTRIAL:
                  </b>{" "}
                  Av. Nueva Granada, Edif. Gran Sabana, PB, Local 1.
                  <br />
                  <br />
                  <b className="text-dark me-3"> LAS AMÉRICAS:</b> Av. Brasil,
                  Urb. Villa Brasil, Manzana 142, Local N. 2 (A 200 mt del
                  Abasto La Española, Sector Villa Antillana).
                  <br />
                  <br />
                  <b className="text-dark me-3">PLAZA ATLÁNTICO:</b> C.C. Plaza
                  Atlántico Mall, final Av. Atlántico, Local PB-12.
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionEight">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingEight">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="true"
                  aria-controls="collapseEight"
                >
                  Carabobo
                </button>
              </h2>
              <div
                id="collapseEight"
                className="accordion-collapse collapse"
                aria-labelledby="headingEight"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <br />
                  <br />
                  <b className="text-dark me-3">VALENCIA CENTRO:</b> Av.
                  Miranda, Local 118-31 MRW (Detrás de IMGEVE Av. Bolívar
                  Norte).
                  <br />
                  <br />
                  <b className="text-dark me-3">VALENCIA BIG LOW:</b> Calle 97,
                  C.C. Ciudad Valencia, PB, Local B4. Urb. Zona Industrial
                  Castillito.
                  <br />
                  <br />
                  <b className="text-dark me-3">GUACARA:</b> Av. Carabobo con
                  Calle Vargas y Lovera, C.C. Carabobo, PB, Local 15.
                  <br />
                  <br />
                  <b className="text-dark me-3">ALIANZA MALL:</b> Carretera
                  Nacional Guacara, Urb. Ciudad Alianza, C.C. Central Guacara,
                  Local 27 (Frente a Supermercado Central Madeirense).
                  <br />
                  <br />
                  <b className="text-dark me-3">
                    LOS GUAYOS ZONA INDUSTRIAL:
                  </b>{" "}
                  Carretera Nacional de Los Guayos con Av. 67, Zona Industrial
                  Municipal Norte, Edif. Tacarigua, Local 2.
                  <br />
                  <br />
                  <b className="text-dark me-3">PUERTO CABELLO:</b> Calle
                  Rondon, Edif. El Naranjal, Local 2 (Frente a Templo El
                  Refugio, al lado de la Clínica San Agustín).
                  <br />
                  <br />
                  <b className="text-dark me-3">MORÓN:</b> Av. Yaracuy, N. 52
                  (Frente a Banco Banesco, al lado de Banco bod).
                  <br />
                  <br />
                  <b className="text-dark me-3">FLOR AMARILLO:</b> Av. Las
                  Industrias cruce con Av. Ppal., Urb. Parque Valencia, C.C:
                  Mega Mercado, PB, Local 8C1.
                  <br />
                  <br />
                  <b className="text-dark me-3">EL TRIGAL:</b> Av. Ppal. de
                  Mañongo, C.C. Patio Trigal, PB, Local 208, Urb Trigal Norte.
                  <br />
                  <br />
                  <b className="text-dark me-3">NAGUANAGUA:</b> Av. 96-B, C.C.
                  Cristal, PB, Local B-06 (A dos locales de Banco Bicentenario).
                  <br />
                  <br />
                  <b className="text-dark me-3">MARIARA:</b> Av. Bolívar, Local
                  N. 54, Sector Guamacho, Barrio El Carmen (A 100 mts de
                  Farmacia Coromoto)
                  <br />
                  <br />
                  <b className="text-dark me-3">SAN JOAQUÍN:</b> Av. Carabobo,
                  Calle Miranda, C.C. Eurocenter, PB, Local 13.
                  <br />
                  <br />
                  <b className="text-dark me-3">VALENCIA SUR:</b> Autopista
                  Valencia Campo Carabobo, C.C. El Prado, PB, Local B6, Los
                  Caobos.
                  <br />
                  <br />
                  <b className="text-dark me-3">BEJUMA:</b> Av. Carabobo entre
                  Calle Piar y Calle Valencia, Local S/N.
                  <br />
                  <br />
                  <b className="text-dark me-3">LA CANDELARIA:</b> Av. Aranzazu,
                  Edif. Vorma, PB, Local PB-3, La Candelaria.
                  <br />
                  <br />
                  <b className="text-dark me-3">AV. LAS FERIAS:</b> Av. Bolívar
                  Sur (Las Ferias) con Calle Silva N. 93-23, C.C. Metro Lara,
                  Local C 19.
                  <br />
                  <br />
                  <b className="text-dark me-3">
                    VALENCIA ZONA INDUSTRIAL:
                  </b>{" "}
                  Av. Prolongación Michelena, C.C. Mycra, Local 10, Zona
                  Industrial Valencia.
                  <br />
                  <br />
                  <b className="text-dark me-3">LA ISABELICA:</b> Av. 04, Sector
                  10, Vereda 14, Local 01, Urb. La Isabelica.
                  <br />
                  <br />
                  <b className="text-dark me-3">GÜIGÜE:</b> Av. Miranda con Av.
                  Michelena, Local 8-54, Sector Güigüe.
                  <br />
                  <br />
                  <b className="text-dark me-3">EL PARRAL:</b> Las 4 Avenidas,
                  Centro Profesional Ceravica, PB, Local 2, Urb. El Parral.
                  <br />
                  <br />
                  <b className="text-dark me-3">SAN DIEGO:</b> Av. Don Julio
                  Centeno, C.C. Metro Plaza, PB, Local 33.
                  <br />
                  <br />
                  <b className="text-dark me-3">MORRO II:</b> Av. 73 vía de
                  servicio, Casa N. 656, Local 656-B (Al lado de McDonald’s a 50
                  mts de C.C. Fin de Siglo).
                  <br />
                  <br />
                  <b className="text-dark me-3">VALENCIA NORTE:</b> Av. Bolívar
                  Norte, Sector Majay, Local N. 151-54 (Frente a Torre Principal
                  Banco BNC).
                  <br />
                  <br />
                  <b className="text-dark me-3">SAN DIEGO:</b> Av. Don Julio
                  Centeno, Calle 103, C.C. Prati, PB, Local L-4, Urb. Terrazas
                  de Castillito (Frente a Mar de la Cerámica).
                  <br />
                  <br />
                  <b className="text-dark me-3">VALENCIA GOBERNACIÓN:</b> Calle
                  Paez entre Montes de Oca y Carabobo, C.C. Papin, PB, Local 1,
                  Centro (Cerca del Capitolio).
                  <br />
                  <br />
                  <b className="text-dark me-3">AV. LARA:</b> Av. Lara con Calle
                  Uslar, Local 87-107 (Frente a Molinari Caccia Guerra).
                  <br />
                  <br />
                  <b className="text-dark me-3">PARAPARAL:</b> Urb. Paraparal,
                  Av. Ppal. C.C. Cristal, Local 2 (Al lado de Pasteleria
                  Capuchino).
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionNine">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingNine">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="true"
                  aria-controls="collapseNine"
                >
                  Cojedes
                </button>
              </h2>
              <div
                id="collapseNine"
                className="accordion-collapse collapse"
                aria-labelledby="headingNine"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">SAN CARLOS:</b> Calle Sucre
                  entre Zamora y Libertad, Local 8-33.
                  <br />
                  <br />
                  <b className="text-dark me-3">TINAQUILLO:</b> Av. Madariaga
                  entre Calle Cedeño y Calle Negro Primero.
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionTen">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingTen">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="true"
                  aria-controls="collapseTen"
                >
                  Delta Amacuro
                </button>
              </h2>
              <div
                id="collapseTen"
                className="accordion-collapse collapse"
                aria-labelledby="headingTen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">TUCUPITA:</b> Calle Dalla Costa
                  N. 22, Local 1, Edif. Ciarcia (Frente a Plaza Bolívar).
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionNine">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingEleven">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven"
                  aria-expanded="true"
                  aria-controls="collapseEleven"
                >
                  Falcón
                </button>
              </h2>
              <div
                id="collapseEleven"
                className="accordion-collapse collapse"
                aria-labelledby="headingEleven"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">CORO:</b> Calle Zamora esquina
                  Callejón Las Flores e Iturbe, Local S/N, Sector Chimpire
                  <br />
                  <br />
                  <b className="text-dark me-3">TUCACAS:</b>Av. Libertador de
                  Tucacas, Local 1 (Diagonal a Hotel La Suerte).
                  <br />
                  <br />
                  <b className="text-dark me-3">CAJA DE AGUA:</b>
                  Calle Manuelita Sáenz entre Calle Comercio y Providencia,
                  Local 1, Sector Caja de Agua (Diagonal a Iglesia Los
                  Mormones).
                  <br />
                  <br />
                  <b className="text-dark me-3">PUERTA MARAVEN:</b>
                  Calle San Román entre Av. General Pelayo y Av. Ollarvides
                  (Diagonal a Rías Altas).
                  <br />
                  <br />
                  <b className="text-dark me-3">EL CASTILLO:</b>
                  Av. Manaure esquina con Calle Monzon, C.C. El Castillo Don
                  Leoncio, PB, Local 10.
                  <br />
                  <br />
                  <b className="text-dark me-3">PUNTO FIJO:</b>
                  Calle Monagas entre Garcés y Zamora, Edif. Lucriscar, PB.
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionTwelve">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingTwelve">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwelve"
                  aria-expanded="true"
                  aria-controls="collapseTwelve"
                >
                  Guárico
                </button>
              </h2>
              <div
                id="collapseTwelve"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwelve"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">SAN JUAN DE LOS MORROS:</b>{" "}
                  Calle El Carmen, Edif. Rauseo, Local 2 (Bajando por la
                  Gobernación, diagonal a CANTV).
                  <br />
                  <br />
                  <b className="text-dark me-3">CALABOZO:</b> Carrera 12 entre
                  Calle 4 y 5, C.C. Coromoto (Frente a Plaza Bolívar).
                  <br />
                  <br />
                  <b className="text-dark me-3">VALLE DE LA PASCUA:</b> Calle
                  González Padrón, C.C. Star Center, Local B-10.
                  <br />
                  <br />
                  <b className="text-dark me-3">ZARAZA:</b> Calle Bolívar entre
                  Ayacucho y Sucre, Local MRW, PB (A 100 mts del SAIME).
                  <br />
                  <br />
                  <b className="text-dark me-3">TUCUPIDO:</b> Calle Salom, N.
                  24, Local MRW (Diagonal a Alcaldía entre San Pablo y Zaraza).
                  <br />
                  <br />
                  <b className="text-dark me-3">ALTAGRACIA DE ORITUCO:</b> Calle
                  Hurtado Ascanio cruce con Vuelvan Cara, Edif. Residencias La
                  Paz, PB, Local 2, Sector Las Brisas del Este.
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionThirteen">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingThirteen">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThirteen"
                  aria-expanded="true"
                  aria-controls="collapseThirteen"
                >
                  Lara
                </button>
              </h2>
              <div
                id="collapseThirteen"
                className="accordion-collapse collapse"
                aria-labelledby="headingThirteen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">BARQUISIMETO CENTRO:</b> Av.
                  Venezuela entre Calle 39 y 40, N. 39-55, Sentido Oeste-Este.
                  <br />
                  <br />
                  <b className="text-dark me-3">CANAIMA:</b> Calle 55 entre Av.
                  Pedro León Torres y Carrera 19, C.C. Canaima, Local F-02. Zona
                  Este.
                  <br />
                  <br />
                  <b className="text-dark me-3">ANDRÉS BELLO:</b> Av. Andrés
                  Bello entre Carrera 22 y 23, Edif. Pulca 2, Locales 2 y 3.
                  <br />
                  <br />
                  <b className="text-dark me-3">BARQUISIMETO OESTE:</b> Av.
                  Florencio Jimenez, C.C. Arcoiris, Local 5, PB (Frente a
                  Decanato de la UCLA).
                  <br />
                  <br />
                  <b className="text-dark me-3">CARORA:</b> Av. Francisco de
                  Miranda, Calle 15A y 1, Casa N. 15, Sector Egidio Montesino (A
                  50 mts de Plaza Chio Zubillaga).
                  <br />
                  <br />
                  <b className="text-dark me-3">BARQUISIMETO ESTE:</b> Calle 15
                  entre Carrera 20 y 21, Local 5 (Diagonal a Clínica San
                  Francisco).
                  <br />
                  <br />
                  <b className="text-dark me-3">AV. MORAN:</b> Carrera 21 entre
                  Av. Moran y Calle 8, C.C. Plaza Sevilla, Local 28 y 29.
                  <br />
                  <br />
                  <b className="text-dark me-3">CABUDARE:</b> Av. Santa Bárbara
                  entre Calle Guillermo Alvizu y La Cruz, C.C. Santa Barbara,
                  Local 2 (Detras de Ferreteria Tabure).
                  <br />
                  <br />
                  <b className="text-dark me-3">QUIBOR:</b> Calle 8 entre Av. 8
                  y 9 (A 200 mts de Supermercado La Palma).
                  <br />
                  <br />
                  <b className="text-dark me-3">VALLE LINDO:</b> Autopista vía
                  Duaca, KM 10, Sector Sabana Grande (Al lado de Farmacia San
                  Ignacio).
                  <br />
                  <br />
                  <b className="text-dark me-3">
                    BARQUISIMETO NUEVA SEGOVIA:
                  </b>{" "}
                  Calle 3 entre Carrera 1 y Av. Lara, Casa N. AL-99, Urb. Nueva
                  Segovia.
                  <br />
                  <br />
                  <b className="text-dark me-3">PATARATA:</b> Av. Libertador
                  entre Av. Argemiro Bracamonte y Av. López Contreras, Sector
                  Patarata, Centro Recreacional Parque Jardín, Local 4.
                  <br />
                  <br />
                  <b className="text-dark me-3">BABILOM:</b> Calle 19 con Av.
                  Libertador, Zona Industrial I, C.C. Libertador, Local 13-B.
                  <br />
                  <br />
                  <b className="text-dark me-3">EL RECREO:</b> Av. Libertador,
                  C.C. El Rosario, Local 5 (Frente al IPASME).
                  <br />
                  <br />
                  <b className="text-dark me-3">CABUDARE SUR:</b> Av. El Placer,
                  Local Trigalpa N. 7, Urb. El Trigal, Los Rastrojos.
                  <br />
                  <br />
                  <b className="text-dark me-3">AV. VENEZUELA:</b> Av. Venezuela
                  con Calle 21, Edif. Lazio, Local 2.
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionFourteen">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingFourteen">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFourteen"
                  aria-expanded="true"
                  aria-controls="collapseFourteen"
                >
                  Mérida
                </button>
              </h2>
              <div
                id="collapseFourteen"
                className="accordion-collapse collapse"
                aria-labelledby="headingFourteen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">MÉRIDA CUBO ROJO:</b> Av.
                  Gonzalo Picón con Viaducto Miranda, C.C. Cubo Rojo, PB, Local
                  2,4,6,7,8, Sector Glorias Patrias
                  <br />
                  <br />
                  <b className="text-dark me-3">
                    MÉRIDA PASEO DE LA FERIA:
                  </b>{" "}
                  Av. Don Tulio Febres Cordero con Calle 29, Edif. El Paseo, PB,
                  Local único (Detrás del Edif. Administrativo de la ULA).
                  <br />
                  <br />
                  <b className="text-dark me-3">EL VIGÍA:</b> Calle 3 con Av.
                  Don Pepe Rojas, Local Galpón S/N, Barrio Bolívar.
                  <br />
                  <br />
                  <b className="text-dark me-3">TOVAR:</b> Carrera 4ta, C.C. El
                  Llano, PB, Local 4, Sector El Llano Tovar.
                  <br />
                  <br />
                  <b className="text-dark me-3">EJIDO:</b> Av. Fernández Peña,
                  Casa PB, N. 133-C, Sector Montalbán (A 100 mts de Diario
                  Frontera)
                  <br />
                  <br />
                  <b className="text-dark me-3">MÉRIDA MILLA:</b> Av. 5 con
                  esquina Calle 16, Local N. 14-106, Sector Belén.
                  <br />
                  <br />
                  <b className="text-dark me-3">MÉRIDA LOS PROCERES:</b> Av. Los
                  Proceres, Calle La Orquídea, Minicentro Comercial Don Luis,
                  Local 2B MRW.
                  <br />
                  <br />
                  <b className="text-dark me-3">TUCANIZON:</b> Carretera
                  Panamericana, Sector El Carmen, Galpón 1 (Al lado de Estacion
                  de Servicio El Indio).
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionFifteen">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingFifteen">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFifteen"
                  aria-expanded="true"
                  aria-controls="collapseFifteen"
                >
                  Miranda
                </button>
              </h2>
              <div
                id="collapseFifteen"
                className="accordion-collapse collapse"
                aria-labelledby="headingFifteen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">CHARALLAVE:</b> Calle 9, Av.
                  Jose Gregorio Hernández con Av. Bolívar, Edif. Los Samanes, N.
                  3, Sector Casco Central.
                  <br />
                  <br />
                  <b className="text-dark me-3">OCUMARE DEL TUY:</b> Av. Miranda
                  con Calle Toribio Mota, Edif. Televisa, PB (Frente a parada de
                  Parosca).
                  <br />
                  <br />
                  <b className="text-dark me-3">GUARENAS:</b> Calle 19 de Abril
                  entre Calle Comercio y Andrés Bello, Local 1 (A media cuadra
                  de Plaza La Candelaria).
                  <br />
                  <br />
                  <b className="text-dark me-3">GUATIRE</b>: Calle Zamora, N.
                  47, Local PB-1, Edif. Quinta Arelis.
                  <br />
                  <br />
                  <b className="text-dark me-3">
                    SAN ANTONIO DE LOS ALTOS:
                  </b>{" "}
                  Carretera Panamericana, KM 16, C.C. La Casona II, Piso 1,
                  Local 2-17 (Al lado de Cinex).
                  <br />
                  <br />
                  <b className="text-dark me-3">HIGUEROTE:</b> Calle el Río,
                  C.C. Martí Plaza, Local 1.
                  <br />
                  <br />
                  <b className="text-dark me-3">RÍO CHICO:</b> Calle Comercio,
                  Local MRW (Al lado de Farmacia Las Mercedes) Río Chico.
                  <br />
                  <br />
                  <b className="text-dark me-3">SANTA TERESA DEL TUY:</b> Calle
                  Ayacucho, Edif. Don Guillermo, Piso 1, Ofic. 4, Zona Centro.
                  <br />
                  <br />
                  <b className="text-dark me-3">CÚA:</b> Urb. Jardines de Santa
                  Rosa, C.C. El Colonial, Local 26-A.
                  <br />
                  <br />
                  <b className="text-dark me-3">GUATIRE OASIS:</b> Av.
                  Intercomunal Guarenas Guatire, C.C. Oasis Center, PB, Local 15
                  (Frente a Banco Bicentenario).
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionSixteen">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingSixteen">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSixteen"
                  aria-expanded="true"
                  aria-controls="collapseSixteen"
                >
                  Monagas
                </button>
              </h2>
              <div
                id="collapseSixteen"
                className="accordion-collapse collapse"
                aria-labelledby="headingSixteen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">PUNTA DE MATA:</b> Calle 5 de
                  Julio cruce con Nuev, Local WRW, (Detrás del Banco Caroní).
                  <br />
                  <br />
                  <b className="text-dark me-3">MATURÍN CENTRO:</b> Calle 14
                  (Antigua Av. Rojas) cruce con Calle 10 (Antigua Barreto),
                  Edif. Bravo, PB, Local 1 (Diagonal a la CANTV).
                  <br />
                  <br />
                  <b className="text-dark me-3">TEMBLADOR:</b> Calle Bolívar, N.
                  70-A, Sector La Plaza (Al lado de Carnicería El Brahmon).
                  <br />
                  <br />
                  <b className="text-dark me-3">MATURÍN NORTE:</b> Av. Ppal. Vía
                  Viboral, C.C. Virgen del Valle, Local 28-A, PB, Sector Tipuro,
                  Parroquia Boquerón.
                  <br />
                  <br />
                  <b className="text-dark me-3">
                    MATURÍN ZONA INDUSTRIAL:
                  </b>{" "}
                  Calle Principal de La Cruz de La Paloma cruce con La
                  Macarenas, PB, Local 5.
                  <br />
                  <br />
                  <b className="text-dark me-3">MATURÍN AV. RAÚL LEONI:</b> Av.
                  Raul Leoni entre Carrera 3 (Antigua Av. Rivas) y Carrera 4
                  (Antigua Prolongación), Frente al Polideportivo.
                  <br />
                  <br />
                  <b className="text-dark me-3">MATURÍN LA FLORESTA:</b> Carrera
                  1, N. 4, C.C. La Redoma, PB, Local 5, Sector Brisas del
                  Aeropuerto (Diagonal al SENIAT).
                  <br />
                  <br />
                  <b className="text-dark me-3">MATURÍN PLAZA EL INDIO:</b> Av.
                  Bicentenario, Edif. Zamora, PB (Al lado de Línea de Taxi Lo
                  Mejor de lo Mejor).
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionSeventeen">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingSeventeen">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeventeen"
                  aria-expanded="true"
                  aria-controls="collapseSeventeen"
                >
                  Nueva Esparta
                </button>
              </h2>
              <div
                id="collapseSeventeen"
                className="accordion-collapse collapse"
                aria-labelledby="headingSeventeen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">JUAN GRIEGO:</b> Calle Guevara,
                  N. 12 B entre Calle La Marina y Marcano (Diagonal a Comercial
                  Juan Griego).
                  <br />
                  <br />
                  <b className="text-dark me-3">EL ESPINAL:</b> Av. Juan
                  Bautista Arismendi, Sector La Encrucijada del Espinal vía San
                  Juan Bautista (A 100 mts de la Regional El Espinal).
                  <br />
                  <br />
                  <b className="text-dark me-3">JOVITO VILLALBA:</b> Av. Jovito
                  Villalba, Local Estación de Servicio Maneiro, N. 9493, Urb.
                  San Lorenzo, Pampatar.
                  <br />
                  <br />
                  <b className="text-dark me-3">NUEVA CADIZ:</b> Av. Juan
                  Bautista Arismendi, Edif. Ultramar, Local 2, Sector Los Cocos
                  (Diagonal a Citroen).
                  <br />
                  <br />
                  <b className="text-dark me-3">PORLAMAR CENTRO:</b> Calle
                  Velázquez con esquina Fajardo, Casa S/N, Sector Centro.
                  <br />
                  <br />
                  <b className="text-dark me-3">VILLA ROSA:</b> Av. Juan
                  Bautista Arismendi, Galpón Seven-Art, PB, Sector San Antonio
                  Norte.
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionNineteen">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingNineteen">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNineteen"
                  aria-expanded="true"
                  aria-controls="collapseNineteen"
                >
                  Portuguesa
                </button>
              </h2>
              <div
                id="collapseNineteen"
                className="accordion-collapse collapse"
                aria-labelledby="headingNineteen"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">GUANARE:</b> Av. Jose María
                  Vargas, C.C. Revica, Galpón N. 4, Sector El Cambio.
                  <br />
                  <br />
                  <b className="text-dark me-3">ACARIGUA:</b> Calle 22 entre Av.
                  Libertador y Alianza (Diagonal a Panadería Trebol).
                  <br />
                  <br />
                  <b className="text-dark me-3">CENTRO LOS LLANOS:</b> Calle 31
                  con Av. 28, C.C. Los Llanos, PB, Local 5.
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionTwenty">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingTwenty">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwenty"
                  aria-expanded="true"
                  aria-controls="collapseTwenty"
                >
                  Sucre
                </button>
              </h2>
              <div
                id="collapseTwenty"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwenty"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">CUMANÁ:</b> Calle Marino, Edif.
                  Tunimiquire, Local 1.
                  <br />
                  <br />
                  <b className="text-dark me-3">CARUPANO:</b> Av. Universitaria,
                  Edif. Prosein, Piso Mezanina, Local B, Sector Los Molinos.
                  <br />
                  <br />
                  <b className="text-dark me-3">CUMANÁ URDANETA:</b> Calle 4ta
                  Transversal Av. Gran Mariscal cruce con Urdaneta, Sector Santa
                  Rosa, Edif. Ceu, PB, Local 2.
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionTwentyone">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingTwentyone">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwentyone"
                  aria-expanded="true"
                  aria-controls="collapseTwentyone"
                >
                  Táchira
                </button>
              </h2>
              <div
                id="collapseTwentyone"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwentyone"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">BARRIO OBRERO CARRERA 20:</b>{" "}
                  Barrio Obrero, Calle 10, Carrera 20, Casa N. 9-108, Local 6
                  (Frente a Licorería 9 con 19).
                  <br />
                  <br />
                  <b className="text-dark me-3">
                    SAN CRISTOBAL CONCORDIA:
                  </b>{" "}
                  Calle 4bis cruce con Carrera 6 y 7, Edif. Ramírez, PB, Local 1
                  (Dtrás del Diario La Nación).
                  <br />
                  <br />
                  <b className="text-dark me-3">SAN ANTONIO DEL TÁCHIRA:</b> Av.
                  Venezuela con Calle 7, Edif. Real, Local 7-09, Sector Centro
                  (A tres cuadras de la Aduana Principal).
                  <br />
                  <br />
                  <b className="text-dark me-3">LA FRÍA:</b> Carrera 11 entre
                  Calle 5 y 6, N. 4-49, Municipio García de Hevia, La Fría.
                  <br />
                  <br />
                  <b className="text-dark me-3">LA GRITA:</b> Av. Francisco de
                  Cáceres con calle 6, Casa N. 7-9, La Grita (Diagonal al
                  Colegio Santa Rosa de Lima).
                  <br />
                  <br />
                  <b className="text-dark me-3">RUBIO:</b> C.C. Venezia, Locales
                  6 y 7 (Frente a Plaza Bolívar).
                  <br />
                  <br />
                  <b className="text-dark me-3">EL PIÑAL:</b> Calle 1, Vía La
                  Morita, Zona Comercial El Mirador, El Piñal.
                  <br />
                  <br />
                  <b className="text-dark me-3">TARIBA:</b> Calle 8 con Carrera
                  8, Casa N. 7-87, Local 2 (A dos cuadras del Hospital San
                  Antonio)
                  <br />
                  <br />
                  <b className="text-dark me-3">BARRIO OBRERO:</b> Carrera 22
                  esquina Pasaje Pirineos, N. 21-97, Sector Barrio Obrero.
                  <br />
                  <br />
                  <b className="text-dark me-3">UREÑA:</b> Calle 5 con esquina
                  Carrera 6, Edif. Sofi, PB, Local 103, 104, 105, 106 y 107,
                  Barrio La Guajira.
                  <br />
                  <br />
                  <b className="text-dark me-3">SAN CRISTÓBAL CENTRO:</b> 7ma
                  Av. entre Calle 5 y 6, C.C. Partenón, PB, Local 3 (Diagonal a
                  Torre Unión, a cuadra y media de Plaza Bolívar).
                  <br />
                  <br />
                  <b className="text-dark me-3">PARAMILLO:</b> Av. Los Agustinos
                  esquina Calle 4, Local 3-70, Urb. El Lobo, Sector Paramillo.
                  <br />
                  <br />
                  <b className="text-dark me-3">SAN CRISTÓBAL CENTRO:</b>{" "}
                  Carrera 5 esquina Calle 7, N. 4-79, San Juan de Colón.
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionTwentyTwo">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingTwentyTwo">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwentyTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwentyTwo"
                >
                  Trujillo
                </button>
              </h2>
              <div
                id="collapseTwentyTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwentyTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">TRUJILLO:</b> Calle
                  Regularización, Sector Centro, C.C. Geminis Center, PB, Local
                  3 (Frente a Plaza Bolívar).
                  <br />
                  <br />
                  <b className="text-dark me-3">VALERA:</b> Calle 5 entre Av.
                  Bolívar y 9, Edif. Profesional Don Pepe, PB, Local MRW.
                  <br />
                  <br />
                  <b className="text-dark me-3">CARACHE:</b> Av. Ppal., Casa
                  S/N, Sector Palo Negro.
                  <br />
                  <br />
                  <b className="text-dark me-3">BOCONÓ:</b> Av. Miranda cruce
                  con Andrés Bello, Local 2, Sector Centro.
                  <br />
                  <br />
                  <b className="text-dark me-3">VALERA LOS LIMONCITOS:</b> Av.
                  Bolívar esquina con Calle 22, Edif. Residencias Trinitarias
                  II, PB, Local B, Sector Los Limoncitos (Frente a McDonald’s).
                  <br />
                  <br />
                  <b className="text-dark me-3">VALERA CENTRO:</b> Av. 12 con
                  Calle 08 y 09, Edif. Beatriz, PB, Local 01, Sector Centro.
                  <br />
                  <br />
                  <b className="text-dark me-3">SABANA DE MENDOZA:</b> Av. Las
                  Flores entre Calle Piar y Comercio, Local 4.
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionTwentythree">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingTwentythree">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwentythree"
                  aria-expanded="true"
                  aria-controls="collapseTwentythree"
                >
                  La Guaira
                </button>
              </h2>
              <div
                id="collapseTwentythree"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwentythree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">CATIA LA MAR:</b> Calle 13
                  Atlantida cruce con Pemysa (Frente a Oficina CORPOELEC).
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionTwentyFour">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingTwentyFour">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwentyFour"
                  aria-expanded="true"
                  aria-controls="collapseTwentyFour"
                >
                  Yaracuy
                </button>
              </h2>
              <div
                id="collapseTwentyFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwentyFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">SAN FELIPE:</b> Calle 12 entre
                  Av. 8 y 9, Edif. Don Jorge, PB, Local 3, Sector Caja de Agua.
                  <br />
                  <br />
                  <b className="text-dark me-3">NIRGUA:</b> Av. 5 entre Calle 4
                  y 5, Edif. Murcia, Local 3, Sector Plaza Sucre (Diagonal al
                  Banco Provincial).
                  <br />
                  <br />
                  <b className="text-dark me-3">YARITAGUA:</b> Calle 17 entre
                  Carrera 7 y 8, Local S/N (Diagonal al Registro Civil).
                </div>
              </div>
            </div>
          </div>

          <div className="accordion mt-3" id="accordionTwentySix">
            <div className="accordion-item rounded border-0">
              <h2 className="accordion-header" id="headingTwentySix">
                <button
                  className="accordion-button rounded fs-5 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwentySix"
                  aria-expanded="true"
                  aria-controls="collapseTwentySix"
                >
                  Zulia
                </button>
              </h2>
              <div
                id="collapseTwentySix"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwentySix"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body font2">
                  <b className="text-dark me-3">MARACAIBO LA LIMPIA:</b> Av. 28,
                  Edif. Rodríguez y Barboza, PB; Local 01, N. 14-54 (Diagonal a
                  Hotel Maracaibo Suites).
                  <br />
                  <br />
                  <b className="text-dark me-3">CIRCUNVALACIÓN 2:</b> Parroquia
                  Cecilio Acosta, Municipio Maracaibo, Av. 58, Circunvalación N.
                  2, Edif. Casa SAAS, PB, Local 98E-164 entre calle 98E y 99,
                  Barrio Andrés Eloy Blanco.
                  <br />
                  <br />
                  <b className="text-dark me-3">LOS OLIVOS:</b> Av. 28 La Limpia
                  con Av. 69, Local 69B-09, Sector Los Aceitunos (Al lado de la
                  E/S Los Aceitunos).
                  <br />
                  <br />
                  <b className="text-dark me-3">MARACAIBO NORTE:</b> Av. Paul
                  Moreno, antigua Fuerzas Armadas con calle 20, Edif. Milenium,
                  Casa SAAS S/N.
                  <br />
                  <br />
                  <b className="text-dark me-3">MARACAIBO INDIO MARA:</b> Calle
                  78, Dr. Portillo entre Av. 17 y 18, N. 17-35 CC Don Julio
                  (Diagonal al Banco Provincial), Sector El Paraíso.
                  <br />
                  <br />
                  <b className="text-dark me-3">CIUDAD OJEDA:</b> Av.
                  Intercomunal, Local MRW, Sector Las Morochas (Frente a la
                  Bomba Central).
                  <br />
                  <br />
                  <b className="text-dark me-3">MANZANILLO:</b> Calle 10 La
                  Unión con Av. 24, C.C. B & B, PB, Local 2, Sector Manzanillo.
                  <br />
                  <br />
                  <b className="text-dark me-3">COROMOTO:</b> Urb. Coromoto, Av.
                  40, C.C. Villa Mall, PB, N. 161-44, Local 4 (Frente a la
                  emisora Alegría Stereo).
                  <br />
                  <br />
                  <b className="text-dark me-3">MACHIQUES:</b> Calle Unión entre
                  Av. Udon Pérez y Valle Frio, Casa S/N, Sector Valle Frio.
                  <br />
                  <br />
                  <b className="text-dark me-3">CABIMAS:</b> Av Intercomunal,
                  Esquina Cumaná, Edif. Intercumaná, PB, Local 02.
                  <br />
                  <br />
                  <b className="text-dark me-3">LA CHINITA:</b> Av. 12 cruce con
                  93 Padilla, Edif. Padilla, Local 33, Sector Casco Central (Al
                  lado de Centro de Literatura Cristiana).
                  <br />
                  <br />
                  <b className="text-dark me-3">MARACAIBO IRAMA:</b> Av. 5 con
                  Calle E, Local E-71, Sector 18 de Octubre (A pocos mts de
                  Frenos Irazuca) Monte Claro.
                  <br />
                  <br />
                  <b className="text-dark me-3">SANTA BARBARA DEL ZULIA:</b> Av.
                  8, Casa N. 5-127, Sector Bolívar, Santa Bárbara.
                  <br />
                  <br />
                  <b className="text-dark me-3">EL VENADO:</b> Av.
                  Independencia, C.C. San Antonio (Los Duartes), Local 01, Mene
                  Grande, Municipio Baralt.
                  <br />
                  <br />
                  <b className="text-dark me-3">CIUDAD OJEDA CENTRO:</b> Calle
                  Faria esquina Calle Lara, C.C. Ted, PB, Local 03, Sector Casco
                  Central.
                  <br />
                  <br />
                  <b className="text-dark me-3">TIA JUANA:</b> Av. Intercomunal
                  de Tia Juana, Sector Ezequiel Zamora, Local 1 (Frente a
                  Prodata Wire-Line).
                  <br />
                  <br />
                  <b className="text-dark me-3">MARACAIBO LA LAGO:</b> Av. 3E
                  entre Calle 72 y 73, Edif. Asociación Zuliana de Ciegos, PB,
                  Local 3, Sector La Lago Maracaibo.
                  <br />
                  <br />
                  <b className="text-dark me-3">CAJA SECA:</b> Nueva Bolivia
                  Mérida, Ctra. Panamericana, Sector La Chertoza, C.C. Stilo,
                  PB, Local 2.
                  <br />
                  <br />
                  <b className="text-dark me-3">CURVA DE MOLINA:</b> Calle 79,
                  N. 92-58 (Frente al Modulo Libertador, al lado de la
                  Ferretería Ranye).
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </div>
    </div>
  );
};

export default Agencies;
