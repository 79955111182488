import React from "react";
import { Link } from "react-router-dom";

const FAQS = () => {
  return (
    <div className="FAQS mt-10">
      <div className="canvas">
        <h1 className="text-center fw-bold">Preguntas Frecuentes</h1>
        <div className="accordion mt-5" id="accordionExample">
          <div className="accordion-item rounded border-0">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button rounded fs-5"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                ¿Quiénes somos?
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2 font2">
                Postalven lo conformamos un equipo de profesionales con más de
                15 años de experiencia en el sector de la logística desde USA,
                Europa y China hacia Venezuela.
                <br />
                <br />
                Ofrecemos un servicio completo: Recepción o Recogida de tus
                paquetes, Almacenaje, Preparación (Reembalaje, Consolidación),
                Expedición y Entrega en toda Venezuela.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                ¿Por qué ofrecemos mejores tarifas?
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Creando alianzas con los principales operadores logísticos y
                haciendo uso de los medios modernos para interactuar con
                nuestros clientes, se reducen los principales gastos operativos,
                logrando mejorar así las tarifas generales.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                ¿Cómo enviar un paquete con Postalven?
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Es muy sencillo. Solo debes abrir tu Buzón Postalven rellenando
                el formulario de solicitud <Link to="/registro">AQUÍ</Link>
              </div>
            </div>
          </div>

          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                ¿Qué es un Buzón Postalven?
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Nuestro Buzón no es más que una dirección que puedes usar para
                enviar todas tus compras online o cualquier otro paquete que
                hayas preparado en casa. Nosotros estamos listos para
                recibirlos, procesarlos y enviarlos a Venezuela con la mayor
                brevedad.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                Agencias de Entrega
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Entregamos en toda Venezuela a través de las Agencias de
                nuestros aliados comerciales. Serás notificado al recibir tus
                envíos en la Agencia que hayas asignado. Puedes consultar el
                listado de Agencias disponibles <Link to="/Agencias">AQUÍ</Link>
              </div>
            </div>
          </div>

          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingSix">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                ¿Puedo rastrear mi Envío?
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Por supuesto. Podrás conocer el estatus de tu Envío en cualquier
                momento que lo desees a través de nuestro localizador con tu
                Orden de Envío. También serás notificado a través de tu email
                cada vez que tu Envío sea actualizado.
              </div>
            </div>
          </div>
          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingSeven">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                ¿Pueden recoger a domicilio?
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Sí. Puedes solicitar cotización para la recogida de tus Envíos
                desde tu Cuenta Postalven y directamente en tu domicilio. Un
                agente contactará contigo con la mayor brevedad.
                <br />
                <br />
                Las recogidas se realizan de lunes a viernes de 9 a 17 Hrs.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingEight">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                Seguro de Contenido
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion-collapse collapse"
              aria-labelledby="headingEight"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Puedes contratar un Seguro que cubra el valor del contenido de tu Envío Aéreo o Marítimo.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingNine">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseNine"
                aria-expanded="false"
                aria-controls="collapseNine"
              >
                ¿Cómo contacto con Atención al Cliente?
              </button>
            </h2>
            <div
              id="collapseNine"
              className="accordion-collapse collapse"
              aria-labelledby="headingNine"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Ponemos a tu disposición un Chat Online, una sección para enviar
                emails desde nuestra web y un Servicio Telefónico de Atención al
                Cliente al +34 919 93 12 13.
              </div>
            </div>
          </div>

          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingTen">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTen"
                aria-expanded="false"
                aria-controls="collapseTen"
              >
                ¿Cómo pago mi envío?
              </button>
            </h2>
            <div
              id="collapseTen"
              className="accordion-collapse collapse"
              aria-labelledby="headingTen"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Desde tu Buzón Postalven podrás consultar el importe que debes abonar de cada Envío. También recibirás un email con tu Factura para que puedas imprimirla si lo deseas.
                <br />
                <br />
                Podrás pagar a través de Transferencia Bancaria, Tarjeta o
                Paypal.
              </div>
            </div>
          </div>

          {/* <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingEleven">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEleven"
                aria-expanded="false"
                aria-controls="collapseEleven"
              >
                ¿Cómo contacto con Atención al Cliente?
              </button>
            </h2>
            <div
              id="collapseEleven"
              className="accordion-collapse collapse"
              aria-labelledby="headingEleven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Ponemos a tu disposición un Chat Online y una sección para
                enviar emails desde nuestra web AQUÍ
                <br />
                <br />
                También puedes llamar al Servicio Telefónico de Atención al
                Cliente +34 919 93 12 13.
              </div>
            </div>
          </div> */}

          <div className="accordion-item mt-3 rounded border-0">
            <h2 className="accordion-header" id="headingtwelve">
              <button
                className="accordion-button rounded fs-5 collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapsetwelve"
                aria-expanded="false"
                aria-controls="collapsetwelve"
              >
                Consejos de Embalaje
              </button>
            </h2>
            <div
              id="collapsetwelve"
              className="accordion-collapse collapse"
              aria-labelledby="headingtwelve"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body font2">
                Es muy importante el correcto embalaje de tus paquetes para
                garantizar que lleguen en perfectas condiciones. Desde Postalven
                te recomendamos lo siguiente:
                <br />
                <br />
                1. Usa preferiblemente cajas de cartón resistentes, pueden ser
                nuevas o usadas pero que estén en buen estado.
                <br />
                <br />
                2. Envases con líquidos, geles y cremas deben estar posicionadas
                de manera vertical con la tapa encintada y si es posible dentro
                de una bolsa plástica cerrada.
                <br />
                <br />
                3. Intenta no dejar espacios vacíos. Puedes rellenar con papel u
                otro material ligero o recortar las cajas para que queden a
                medida.
                <br />
                <br />
                4. Usa plástico de burbujas u otro material acolchado para
                envolver tus artículos frágiles (el Seguro de Contenido no cubre
                artículos de Vidrio, Cristal, Porcelana, Cerámica, Espejos,
                etc.).
                <br />
                <br />
                5. Evita sobrecargar, especialmente en los envíos Marítimos
                donde se maneja una Tarifa Plana sin límite de peso. Una caja
                sobrecargada podría romperse a lo largo de la travesía.
                <br />
                <br />
                6. Identifica SIEMPRE los paquetes que vayamos a recoger a
                domicilio. Puedes marcarlos con rotulador o bolígrafo con tu
                nombre, teléfono y la palabra POSTALVEN en la parte superior de
                cada uno de ellos.
                <br />
                <br />
                7. Utiliza una buena cinta adhesiva para cerrar tus paquetes. Al
                recibirlos los reforzaremos y embalaremos con plástico
                protector.
                <br />
                <br />
                ¡Un buen embalaje inicial es tu responsabilidad!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQS;
