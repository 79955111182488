import React from "react";
import client from "../assets/client.jpg";
import carga from "../assets/carga.jpg";
import { useNavigate } from "react-router-dom";

const Speciality = () => {
  const navigate = useNavigate();

  return (
    <div className="canvas py-3 mt-10">
      <div className="d-flex justify-content-between">
        <div>
          <h1 className="fw-bold">Descuentos Especiales</h1>
          <p className="mt-2 font2">
            En Postalven estudiamos cada caso para ofrecerte las mejores tarifas
            para tus envíos. Seas Particular o Mayorista, tenemos siempre un
            descuento o promoción especial para ti.
          </p>
        </div>
      </div>

      <div
        className="d-flex justify-content-between 
      align-items-center flex-wrap mt-4"
      >
        <div className="special-card  mt-3">
          <div
            className="d-flex align-items-center special-card 
        rounded text-white px-4 py-5 w-100"
            style={{ background: `url(${client})` }}
          >
            <div>
              <p className="fw-bold fs-5">Nuevos Clientes</p>
              <p className="fs-5">Bienvenida Especial</p>
              <p className="fw-bold fs-5">15% DESCUENTO*</p>
              <button
                className="px-3 btn text-white mt-4 special_btn rounded-pill"
                onClick={() => navigate("/registro")}
                style={{ border: "1.99px solid white" }}
              >
                Abrir Buzón
              </button>
            </div>
          </div>
          <p className="mt-1 font2">*Envíos aéreos mayores a 10Kg.</p>
        </div>
        <div
          className="d-flex align-items-center special-card 
        rounded text-white px-4 py-5 mt-3"
          style={{ background: `url(${carga})` }}
        >
          <div>
            <p className="fw-bold fs-5">Mayoristas y Bodegones</p>
            <p className="fs-5">Envíos de Pallets</p>
            <p className="fw-bold fs-5">Desde 29,99€/ft3</p>
            <button
              className="px-3 btn text-white mt-4 special_btn rounded-pill"
              style={{ border: "1.99px solid white" }}
              onClick={() => navigate("/registro")}
            >
              Enviar Pallet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Speciality;
