import React from 'react'
import img1 from "../assets/amazon logo.png"
import img2 from "../assets/aliexpress.png"
import img3 from "../assets/zara.png"
import img4 from "../assets/corte ingles.png"
import img5 from "../assets/mediamarkt.png"
import img6 from "../assets/shein.png"
import img7 from "../assets/TEMU.png"
import img8 from "../assets/zalando.png"

const Compra = () => {
    return (
        <div className="how_works  py-3 mt-10">
            <div className="canvas canvas_col">
                <h1 className="text-center fw-bold">Recibimos tus Compras Online</h1>
                <p className='font2 mt-2 text-center'>Comparte tu carrito de compra o envíanos los enlaces de los productos que deseas adquirir y nosotros nos encargamos de comprar y recibir. Podrás pagar tu carrito de compra en Venezuela.</p>
                <div className="mt-2 d-flex justify-content-center align-items-center flex-wrap gap-3">
                    <img src={img1} width={100}/>
                    <img src={img2} width={100}/>
                    <img src={img3} width={100}/>
                    <img src={img4} width={100}/>
                    <img src={img5} width={100}/>
                    <img src={img6} width={100}/>
                    <img src={img7} width={100}/>
                    <img src={img8} width={100}/>

                </div>
            </div>
        </div>
    )
}

export default Compra