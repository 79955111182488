import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import PageBanner from "../Components/PageBanner";
import { useAuthContext } from "../Contexts/AuthContext";
import axios from "axios";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import registerimg from "../assets/entrar.jpg";
import { withAlert } from "react-alert";
import loader from "../assets/loading.gif";

const Login = ({ alert }) => {
  const { setUser, user } = useAuthContext();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [Vloading, setVLoading] = useState(false);

  const [credentials, setCredentials] = useState({});
  const { id } = useParams();

  const Check = async () => {
    setVLoading(true);
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_BASE_URL + `/api/auth/verify/${id}`
      );
      alert.show(<span className="small">{data}</span>, {
        type: "success",
      });
      setVLoading(false);
    } catch (err) {
      setVLoading(false);
      alert.show(<span className="small">{err.response?.data.error}</span>, {
        type: "error",
      });
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      Check();
    }
  }, [id]);

  const HandleLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/auth/login",
        {
          ...credentials,
          email: credentials.email.toLowerCase(),
        }
      );
      if (data.success) {
        setUser(jwt_decode(data.authToken));
        Cookies.set("token", data.authToken, { expires: 3, secure: true });
        navigate("/");
      }
      setLoading(false);
    } catch (err) {
      alert.show(
        <span className="small">
          {err.response?.data.msg
            ? err.response?.data.msg
            : "RELLENAR CAMPOS"}
        </span>,
        {
          type: "error",
        }
      );
      console.log(err);
      setLoading(false);
    }
  };

  const HandleChange = (e) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  if (user) return <Navigate to={"/"} />;
  if (Vloading)
    return (
      <div style={{ height: "100vh" }}>
        <img src={loader} alt="loading..." className="loader" />
      </div>
    );

  return (
    <div className="Login">
      <PageBanner title={"Entrar"} img={registerimg} />
      <div
        className="d-flex align-items-center
        mt-10 w-50 login_form mx-auto bg-white px-3 py-5 rounded shadow"
      >
        <form className="w-100" onSubmit={HandleLogin}>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              type="email"
              className="form-control w-100"
              id="email"
              aria-describedby="emailHelp"
              onChange={(e) => HandleChange(e)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password" className="form-label">
              Contraseña
            </label>
            <input
              type="password"
              className="form-control w-100"
              id="password"
              onChange={(e) => HandleChange(e)}
            />
            <Link
              to={"/forgotpassword"}
              className="highlight nav-link mt-1 text-end w-100 d-block"
            >
              Recuperar Contraseña
            </Link>
          </div>
          <button type="submit" className="btn continue w-auto">
            {loading ? "Enviando" : "Acceder"}
          </button>
          <p className="text-center mt-2">
            No tengo Cuenta.{" "}
            <Link to="/registro" className="highlight">
              Registrarme Ahora
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default withAlert()(Login);
