import React, { useEffect } from "react";
import PageBanner from "../Components/PageBanner";

const TextPage = ({ title, text }) => {
  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, [title]);
  
  return (
    <div>
      <PageBanner title={title} />

      <div className="canvas">
        <div className="mt-5">
        {text}
        </div>
      </div>
    </div>
  );
};

export default TextPage;
