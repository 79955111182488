import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import Calculator from "./Calculator";
import Navbar from "./Navbar";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Autoplay, Pagination, Navigation } from "swiper";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../Contexts/AuthContext";
// import './styles.css';

const Hero = () => {
  const {user} = useAuthContext();
  const navigate = useNavigate();
  return (
    <div className="Hero_section py-4">
      <Navbar />
      <div
        className="d-flex justify-content-between align-items-center 
      hero_main canvas"
      >
        <div className="w-50">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: false,
            }}
            navigation={false}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div>
                <h1 className="hero_h">
                  Tus Envíos a Venezuela, Simple y 100% Seguro.
                </h1>
                <div className="mt-3">
                  <p>
                    Abre tu Buzón Postalven y envíanos todas tus compras online
                    o paquetes. Recíbelos de manera Rápida y Garantizada.
                  </p>
                  <button
                    className="px-3 btn text-white mt-2 special_btn rounded-pill"
                    style={{ border: "1.99px solid white" }}
                  onClick={()=> navigate("/registro")}
                  >
                    Abrir Buzón
                  </button>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="">
                <h1 className="hero_h">
                Recogidas a domicilio en toda la Unión Europea.
                </h1>
                <div className="mt-3">
                  <p>
                  Solicita cotización para la recogida de tus paquetes en casa o directamente donde nos indique tu proveedor.
                  </p>
                </div>
                <button
                  className="px-3 btn text-white mt-2 special_btn rounded-pill"
                  style={{ border: "1.99px solid white" }}
                  onClick={()=> navigate(user ? "/recogida" : "/registro")}
                >
                  Solicitar Recogida{" "}
                </button>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <Calculator btn={true} />
      </div>
    </div>
  );
};

export default Hero;
