import React, { useEffect, useState } from "react";
import PageBanner from "../Components/PageBanner";
import Calculator from "../Components/Calculator";
import { AiFillWarning } from "react-icons/ai";
import { useAuthContext } from "../Contexts/AuthContext";
import { Navigate } from "react-router-dom";

const Recogida = () => {
  const [show, setShow] = useState(false);
  const { user } = useAuthContext();

  if (!user) return <Navigate to={"/login"} />;

  return (
    <div className="OrderSection">
      <div className="">
        <div className="d-flex pt-0 calculator">
          <AiFillWarning color="red" size={45} className="me-2" />
          <p>
            Instrucciones: Indícanos la siguiente información para recoger tu(s) paquete(s) a domicilio en Península y Canarias. Consultar para recogidas en Baleares y otros países de la Unión Europea. Ver
            {" "} <span className="text-primary fw-bold" type="button"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => setShow(true)}
            > TARIFAS ESPAÑA</span>
          </p>
        </div>
        <Calculator
          advanced={true}
          home={true}
          calculate={true}
          btn={true}
          small={true}
        />
        <p className="mt-3 small text-danger calculator pt-0">
          ATENCIÓN: Todos los paquetes son inspeccionados a través de Rayos X.
          Asegúrate de haber declarado todo el contenido de tu envío,
          especialmente los artículos electrónicos. El cliente será responsable
          de cualquier sanción Aduanal.
        </p>
      </div>

      {show && (
        <div
          className="modal_bg d-flex justify-content-center 
          align-items-center recog"
        >
          <div
            className="modal d-block"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            // aria-hidden="true"
            style={{ zIndex: 30000000 }}

          >
            <div className="modal-dialog" style={{ zIndex: 20000000 }}>
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    TARIFAS DE RECOGIDA A DOMICILIO
                  </h1>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setShow(false);
                    }}
                  ></button>
                </div>
                <div className="modal-body">
                  <span className="fw-bold mt-3">TARIFAS DE RECOGIDA A DOMICILIO</span>
                  <br />
                  <div className="d-flex w-100 flex-wrap justify-content-between gap-3 mt-3">
                    <div className="col-md-5 col-12">
                      <b className="mt-3">PENÍNSULA:</b><br />
                      0,01 a 10 Kg. 12,00€ <br />
                      10,01 a 15 Kg. 15,00€ <br />
                      15,01 a 30 Kg. 20,00€ <br />
                    </div>
                    <div className="col-md-5 col-12">
                      <b className="mt-2">CANARIAS TENERIFE:</b><br />
                      0,01 a 10 Kg. 7,00€<br />
                      10,01 a 15 Kg. 10,00€<br />
                      15,01 a 30 Kg. 15,00€<br />
                    </div>
                    <div className="col-md-5 col-12">
                      <b className="mt-2">CANARIAS GRAN CANARIA:</b><br />
                      0,01 a 10 Kg. 8,00€ <br />
                      10,01 a 15 Kg. 15,00€<br />
                      15,01 a 30 Kg. 20,00€<br />
                    </div>
                    <div className="col-md-5 col-12">
                      <b className="mt-2">CANARIAS ISLAS MENORES:</b><br />
                      0,01 a 10 Kg. 10,00€  <br />
                      10,01 a 15 Kg. 16,50€ <br />
                      15,01 a 20 Kg. 20,00€ <br />
                      20,01 a 25 Kg. 22,50€ <br />
                      25,01 a 30 Kg. 25,00€ <br />
                    </div>
                    <div className="col-md-5 col-12">
                      <b className="mt-2">BALEARES:</b>
                      <br />
                      CONSULTAR
                    </div>
                    <br />
                    AVISO: Estas tarifas son ORIENTATIVAS y podrían cambiar sin previo aviso y en función al volumen de la carga.
                  </div>
                </div>
                <div className="modal-footer">
                  {/* <button className="btn btn-primary">Aceptar</button> */}
                  <button className="btn btn-danger ms-3"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setShow(false);
                    }}>Cerrar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Recogida;
