import axios from "axios";
import React, { useEffect, useState } from "react";
import PageBanner from "../Components/PageBanner";
import { useAuthContext } from "../Contexts/AuthContext";
import { withAlert } from "react-alert";

const ResetPassword = ({ alert }) => {
  const { userdata } = useAuthContext();
  const [data, setData] = useState({});

  const onSubmit = async () => {
    if (data.newpassword === data.confirmpassword) {
      try {
        await axios.put(
          process.env.REACT_APP_BASE_URL + "/api/auth/changepassword",
          {
            email: userdata.email,
            oldpassword: data.oldpassword,
            newpassword: data.newpassword,
          }
        );

        alert.show(<span className="small">¡CONTRASEÑA CAMBIADA!</span>, {
          type: "success",
        });
        setData({ confirmpassword: "", oldpassword: "", newpassword: "" });
      } catch (err) {
        alert.show(<span className="small">{err?.response?.data.msg}</span>, {
          type: "error",
        });
      }
    } else {
      alert.show(<span className="small">¡Contraseñas no coinciden!</span>, {
        type: "error",
      });
    }
  };

  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  return (
    <div>
      <PageBanner title={"Reset Password"} />
      <div className="mt-10 w-50 forgot_form py-3 mx-auto bg-white px-5 rounded shadow">
        <div>
          <label className="fw-bold">Contraseña antigua</label>
          <input
            type={"password"}
            // placeholder="Enter old password!"
            className="form-control mt-1 rounded-0"
            value={data.oldpassword}
            onChange={(e) => setData({ ...data, oldpassword: e.target.value })}
          />
        </div>
        <div className="mt-2">
          <label className="fw-bold">Nueva contraseña</label>
          <input
            type={"password"}
            // placeholder="Enter new password!"
            value={data.newpassword}
            className="form-control mt-1 rounded-0"
            onChange={(e) => setData({ ...data, newpassword: e.target.value })}
          />
          <div className="mt-2">
            <label className="fw-bold">Confirma nueva contraseña</label>
            <input
              type={"password"}
              // placeholder="Confirm password!"
              value={data.confirmpassword}
              className="form-control mt-1 rounded-0"
              onChange={(e) =>
                setData({ ...data, confirmpassword: e.target.value })
              }
            />
          </div>
          <button className="continue w-auto px-3 mx-auto" onClick={onSubmit}>
            Cambiar
          </button>
        </div>
      </div>
    </div>
  );
};

export default withAlert()(ResetPassword);
