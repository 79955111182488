import React from "react";
import { BsFillCheckCircleFill } from "react-icons/bs";
import whyus from "../assets/whyyus.jpg";

const WhyUs = () => {
  return (
    <div className="Whyus mt-10 py-3 fs-60 column-resp">
      <div
        className="d-flex justify-content-between canvas canvas_col"
        style={{ position: "relative" }}
      >
        <img
          src={whyus}
          alt="latinotransfer img"
          className="w-50 me-5 rounded"
          style={{
            objectFit: "cover",
            height: "100vh",
          }}
        />
        <div className="why_content w-50">
          <h1 className="fw-bold">Tus Envíos 100% Garantizados</h1>
          <p className="font2 mt-2 fs-6 text-muted">
            Postalven te ofrece un servicio de envíos seguro, innovador,
            eficiente y económico. Ofrecemos soluciones simples para el cliente
            moderno. Seas particular o mayorista, todo lo que necesitas en un
            solo lugar, con tu Buzón Postalven.
          </p>

          <div className="mt-3">
            <div className="d-flex align-items-center mt-4">
              <BsFillCheckCircleFill color="#fc364e" size={27} />
              <p className="ms-2 fs-5 fw-bold">15 Años de Experiencia</p>
            </div>
            <p className="mt-1 text-muted fs-6 font2">
              {" "}
              Postalven es gestionado por un Equipo de Profesionales con más de
              15 años en el sector logístico China, U.S.A. y Europa hacia
              Venezuela.
            </p>
          </div>
          <div className="mt-3">
            <div className="d-flex align-items-center mt-4">
              <BsFillCheckCircleFill color="#fc364e" size={27} />
              <p className="ms-2 fs-5 fw-bold">Tu Carga Asegurada</p>
            </div>
            <p className="mt-1 text-muted fs-6 font2">
              {" "}
              Puedes contratar un Seguro que cubra el valor del contenido de tu Envío Aéreo o Marítimo.
            </p>
          </div>
          <div className="mt-3">
            <div className="d-flex align-items-center mt-4">
              <BsFillCheckCircleFill color="#fc364e" size={27} />
              <p className="ms-2 fs-5 fw-bold">Localizador 24/7/365</p>
            </div>
            <p className="mt-1 text-muted fs-6 font2">
              {" "}
              Siempre podrás conocer el estado de tu Envío desde nuestro
              Localizador 24/7/365. Además, recibirás notificaciones por cada
              cambio de estado.
            </p>{" "}
          </div>
          <div className="mt-3">
            <div className="d-flex align-items-center mt-4">
              <BsFillCheckCircleFill color="#fc364e" size={27} />
              <p className="ms-2 fs-5 fw-bold">Salidas Semanales</p>
            </div>
            <p className="mt-1 text-muted fs-6 font2">
              {" "}
              Nuestro servicio Express de Envíos Aéreos tiene una regularidad
              semanal. Los Envíos Marítimos se programan una o dos veces al mes.
            </p>{" "}
          </div>
          <div className="mt-3">
            <div className="d-flex align-items-center mt-4">
              <BsFillCheckCircleFill color="#fc364e" size={27} />
              <p className="ms-2 fs-5 fw-bold">Carga Paletizada</p>
            </div>
            <p className="mt-1 text-muted fs-6 font2">
              {" "}
              ¿Eres mayorista? Envíanos tu carga paletizada o solicita cotización para ir a recogerla donde nos indique tu proveedor.
            </p>{" "}
          </div>

          <div className="mt-3">
            <div className="d-flex align-items-center mt-4">
              <BsFillCheckCircleFill color="#fc364e" size={27} />
              <p className="ms-2 fs-5 fw-bold">Buscamos Productos</p>
            </div>
            <p className="mt-1 text-muted fs-6 font2">
              {" "}
              Cuenta con nosotros si lo que deseas es conseguir algún producto
              europeo en específico con la mejor relación calidad - precio.
            </p>{" "}
          </div>
        
        {/* <div className="mt-3">
          <div className="d-flex align-items-center mt-4">
            <BsFillCheckCircleFill color="#fc364e" size={27} />
            <p className="ms-2 fs-5 fw-bold">Compra Asistida</p>
          </div>
          <p className="mt-1 text-muted fs-6 font2">
            {" "}
            Comparte tu carrito de compra o envíanos los enlaces de los productos que deseas adquirir y nosotros nos encargamos de comprar y recibir. Podrás pagar tu carrito de compra en Venezuela.
          </p>{" "}
        </div> */}
        </div>

      </div>
    </div>
  );
};

export default WhyUs;
