import React, { useEffect, useState } from "react";
import PageBanner from "../Components/PageBanner";
import { FaMobileAlt } from "react-icons/fa";
import { GiProcessor } from "react-icons/gi";
import { MdBatteryChargingFull } from "react-icons/md";
import { AiFillCamera } from "react-icons/ai";
import { TbCameraSelfie } from "react-icons/tb";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import loader from "../assets/loading.gif";
import { useAuthContext } from "../Contexts/AuthContext";
import { withAlert } from "react-alert";
import img from "../assets/tienda.jpg";

const ProductDetail = ({ alert }) => {
  const { userdata } = useAuthContext();
  const [loading, setloading] = useState(true);
  const [loading2, setloading2] = useState(false);
  const [data, setData] = useState([]);
  const slug = useParams();
  const navigate = useNavigate();
  const [quantity, setquantity] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [receiver, setReceiver] = useState("");
  const [receiverP, setReceiverP] = useState("");
  const [receiverP2, setReceiverP2] = useState("");
  const [receiverID, setReceiverID] = useState("");
  const [show, setShow] = useState(false);
  const [insurance, setInsurance] = useState(true);
  const [fixPrice, setFixPrice] = useState("");

  const increment = () => {
    setquantity(quantity + 1);
    if (insurance === true) {
      let price =
        (fixPrice
          .replaceAll(",", "dd")
          .replaceAll(".", "cc")
          .replaceAll("dd", ".")
          .replaceAll("cc", "")
          .replaceAll("€", "") /
          100) *
          3 +
        Number(
          fixPrice
            .replaceAll(",", "dd")
            .replaceAll(".", "cc")
            .replaceAll("dd", ".")
            .replaceAll("cc", "")
            .replaceAll("€", "")
        );
      setData((prev) => ({
        ...prev,
        price: (price * (quantity + 1)).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      }));
    } else {
      setData((prev) => ({
        ...prev,
        price: (
          fixPrice
            .toString()
            .replaceAll(",", "dd")
            .replaceAll(".", "cc")
            .replaceAll("dd", ".")
            .replaceAll("cc", "") *
          (quantity + 1)
        ).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      }));
    }
  };

  const decrement = () => {
    if (quantity > 1) {
      setquantity(quantity - 1);
      if (insurance === true) {
        let price =
          (fixPrice
            .replaceAll(",", "dd")
            .replaceAll(".", "cc")
            .replaceAll("dd", ".")
            .replaceAll("cc", "")
            .replaceAll("€", "") /
            100) *
            3 +
          Number(
            fixPrice
              .replaceAll(",", "dd")
              .replaceAll(".", "cc")
              .replaceAll("dd", ".")
              .replaceAll("cc", "")
              .replaceAll("€", "")
          );
        setData((prev) => ({
          ...prev,
          price: (price * (quantity - 1)).toLocaleString("es-ES", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        }));
      } else {
        setData((prev) => ({
          ...prev,
          price: (
            fixPrice
              .toString()
              .replaceAll(",", "dd")
              .replaceAll(".", "cc")
              .replaceAll("dd", ".")
              .replaceAll("cc", "") *
            (quantity - 1)
          ).toLocaleString("es-ES", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        }));
      }
    }
  };

  const Fetch = async () => {
    setloading(true);
    let id = slug.slug;
    const { data } = await axios.get(
      process.env.REACT_APP_BASE_URL + `/api/product/getone?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    );
    let price = (
      (data.data.price
        .replaceAll(",", "dd")
        .replaceAll(".", "cc")
        .replaceAll("dd", ".")
        .replaceAll("cc", "")
        .replaceAll("€", "") /
        100) *
        3 +
      Number(
        data.data.price
          .replaceAll(",", "dd")
          .replaceAll(".", "cc")
          .replaceAll("dd", ".")
          .replaceAll("cc", "")
          .replaceAll("€", "")
      )
    ).toLocaleString("es-ES", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    setData(data.data);
    setFixPrice(data.data.price);
    setData((prev) => ({ ...prev, price: price }));
    setReceiver(userdata.name + " " + userdata.surname);
    setReceiverID(userdata.docId);
    setReceiverP(userdata.phone);
    setReceiverP2(userdata?.phone2);
    setloading(false);
  };
  
  const AddToCart = async () => {
    if (Object.values(userdata)?.length > 0) {
      if ((receiver.length > 0, receiverID.length > 0, receiverP.length > 0)) {
        setRefresh(true);
        setloading2(true);
        await axios.post(
          process.env.REACT_APP_BASE_URL + "/api/orders/new",
          {
            userId: userdata._id,
            name: userdata?.name + " " + userdata?.surname,
            email: userdata.email,
            phone: userdata.phone,
            modality: "Aéreo",
            status: "PAGO PENDIENTE",
            type: "Tienda Online",
            agency: userdata.agency,
            description: {
              ...data,
              quantity,
            },
            receiver,
            receiverID,
            receiverP,
            receiverP2,
            insurance,
          },
          {
            headers: {
              "Content-Type": "application/json",
              token: Cookies.get("token"),
            },
          }
        );

        alert.show(
          <span className="small">Tu solicitud ha sido enviada.</span>,
          {
            type: "success",
          }
        );

        setloading2(false);
      } else {
        alert.show(<span className="small">Completar todos los datos!</span>, {
          type: "error",
        });
      }
    } else {
      navigate("/login");
      setloading2(false);
    }
  };

  const CheckIns = (insurance) => {
    setquantity(1);
    if (insurance === true) {
      let price = (
        (fixPrice
          .replaceAll(",", "dd")
          .replaceAll(".", "cc")
          .replaceAll("dd", ".")
          .replaceAll("cc", "")
          .replaceAll("€", "") /
          100) *
          3 +
        Number(
          fixPrice
            .replaceAll(",", "dd")
            .replaceAll(".", "cc")
            .replaceAll("dd", ".")
            .replaceAll("cc", "")
            .replaceAll("€", "")
        )
      ).toLocaleString("es-ES", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      setData((prev) => ({ ...prev, price: price }));
    } else {
      setData((prev) => ({ ...prev, price: fixPrice }));
    }
  };

  useEffect(() => {
    Fetch();
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  if (loading)
    return (
      <div style={{ height: "100vh" }}>
        <img src={loader} alt="loading..." className="loader" />
      </div>
    );

  return (
    <div>
      <PageBanner title={"Producto Detalles"} img={img} />

      <div className="canvas">
        {!loading && (
          <div className="d-flex mt-5  product_row">
            <div
              className="w-50 img_tienda"
              // style={{background: `url(${data.images[0]})`}}
            >
              <img
                src={
                  process.env.REACT_APP_BASE_URL +
                  "/images/" +
                  data.images[0] +
                  ".jpg"
                }
                className=""
              />
            </div>
            <div className="ms-5 prod_details w-50">
              <h1 className="fs-3 fw-bold">{data.name.toUpperCase()}</h1>
              <p className="fs-5 highlight fw-bold mt-2">€ {data.price}</p>

              <p className="mt-2 fw-bold">Cantidad</p>

              <div className="mt-1">
                <button
                  onClick={decrement}
                  className="border-0 px-2 rounded-1 fs-5"
                  style={{ backgroundColor: "gray", color: "white" }}
                >
                  -
                </button>
                <span style={{ margin: "0 20px" }} className="fs-5">
                  {quantity}
                </span>
                <button
                  onClick={increment}
                  className="border-0 px-2 rounded-1 fs-5"
                  style={{ backgroundColor: "#fc364e", color: "white" }}
                >
                  +
                </button>
              </div>

              <div className="d-flex mt-3">
                <label>Destinatario: </label>
                <div className="ms-3">
                  <input
                    className="form-check-input "
                    type="radio"
                    name="des"
                    id="mismo"
                    style={{ border: "1.99px solid black" }}
                    onChange={(e) => {
                      if (show) {
                        setShow(false);
                        setReceiver(userdata.name + " " + userdata.surname);
                        setReceiverID(userdata.docId);
                        setReceiverP(userdata.phone);
                        setReceiverP2(userdata?.phone2);
                      }
                    }}
                    checked={show === true ? false : true}
                  />
                  <label className="form-check-label ms-2" htmlFor="mismo">
                    Soy yo mismo
                  </label>
                </div>
                <div>
                  <input
                    className="form-check-input ms-2"
                    type="radio"
                    name="des"
                    id="mismo"
                    style={{ border: "1.99px solid black" }}
                    onChange={(e) => {
                      if (e.target.checked) {
                        e.target.checked = true;
                        setShow(true);
                        setReceiver("");
                        setReceiverID("");
                        setReceiverP("");
                        setReceiverP2("");
                      }
                    }}
                    checked={show}
                  />
                  <label className="form-check-label ms-2" htmlFor="mismo">
                    Otro
                  </label>
                </div>
              </div>
              {show === true && (
                <>
                  <div className="mt-3">
                    <div className="d-flex">
                      <label className="">Nombre y Apellido Destinatario</label>
                    </div>
                    <div className="d-flex align-items-center rounded">
                      <input
                        className="form-control me-2"
                        type="text"
                        id="receiver"
                        placeholder=""
                        value={receiver}
                        onInput={(e) => setReceiver(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="mt-3">
                    <label className="">Cédula Destinatario</label>
                    <div className="d-flex align-items-center rounded">
                      <input
                        className="form-control me-2"
                        type="text"
                        id="receiverID"
                        placeholder=""
                        value={receiverID}
                        onInput={(e) => setReceiverID(e.target.value)}
                        required
                      />
                    </div>
                  </div>

                  <div className="d-flex align-items-center mt-3">
                    <div className="">
                      <label className="">Teléfono Destinatario</label>
                      <div className="d-flex align-items-center rounded">
                        <input
                          className="form-control me-2"
                          type="text"
                          id="receiverP"
                          placeholder=""
                          value={receiverP}
                          onInput={(e) => setReceiverP(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="ms-3">
                      <label className="">Teléfono adicional</label>
                      <div className="d-flex align-items-center rounded">
                        <input
                          className="form-control me-2"
                          type="text"
                          id="receiverP2"
                          placeholder=""
                          value={receiverP2}
                          onInput={(e) => setReceiverP2(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {data.description?.description?.length > 0 ? (
                <div>
                  <p className="mt-3 fw-bold">Detalles</p>
                  <p className="mt-1">{data.description.description}</p>
                </div>
              ) : (
                <>
                  <p className="mt-3 fw-bold">General Specs</p>
                  <table className="w-50 table  table-bordered mt-2">
                    <tr>
                      <td
                        style={{
                          borderLeft: "1.99px solid gainsboro",
                          borderRight: "1.99px solid gainsboro",
                        }}
                      >
                        <div className="text-center">
                          <FaMobileAlt size={20} />
                          <p className="small">Display</p>
                        </div>
                      </td>
                      <td
                        style={{
                          borderRight: "1.99px solid gainsboro",
                        }}
                      >
                        <div>{data.description.display} Inches</div>
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          borderLeft: "1.99px solid gainsboro",
                          borderRight: "1.99px solid gainsboro",
                        }}
                      >
                        <div className="text-center">
                          <GiProcessor size={25} />
                          <p className="small">RAM</p>
                        </div>
                      </td>
                      <td
                        style={{
                          borderRight: "1.99px solid gainsboro",
                        }}
                      >
                        <div>{data.description.ram}GB</div>
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          borderLeft: "1.99px solid gainsboro",
                          borderRight: "1.99px solid gainsboro",
                        }}
                      >
                        <div className="text-center">
                          <MdBatteryChargingFull size={25} />
                          <p className="small">Battery</p>
                        </div>
                      </td>
                      <td
                        style={{
                          borderRight: "1.99px solid gainsboro",
                        }}
                      >
                        <div>{data.description.battery} mAh</div>
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          borderLeft: "1.99px solid gainsboro",
                          borderRight: "1.99px solid gainsboro",
                        }}
                      >
                        <div className="text-center">
                          <AiFillCamera size={25} />
                          <p className="small">Back Camera</p>
                        </div>
                      </td>
                      <td
                        style={{
                          borderRight: "1.99px solid gainsboro",
                        }}
                      >
                        <div>{data.description.back} MP</div>
                      </td>
                    </tr>

                    <tr>
                      <td
                        style={{
                          borderLeft: "1.99px solid gainsboro",
                          borderRight: "1.99px solid gainsboro",
                        }}
                      >
                        <div className="text-center">
                          <TbCameraSelfie size={25} />
                          <p className="small">Front Camera</p>
                        </div>
                      </td>
                      <td
                        style={{
                          borderRight: "1.99px solid gainsboro",
                        }}
                      >
                        <div>{data.description.front}MP</div>
                      </td>
                    </tr>
                  </table>
                </>
              )}

              <div className="mt-3">
                <label className="">
                  Seguro de Contenido (3% sobre Valor del Contenido)
                </label>
                <div className="d-flex align-items-center rounded mt-1">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="insurance1"
                      style={{ border: "1.99px solid black" }}
                      checked={insurance}
                      onChange={() => {
                        setInsurance(true);
                        CheckIns(true);
                      }}
                    />
                    <label className="form-check-label" htmlFor="insurance1">
                      Si
                    </label>
                  </div>
                  <div className="form-check ms-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="insurance2"
                      style={{ border: "1.99px solid black" }}
                      checked={!insurance}
                      onChange={() => {
                        setInsurance(false);
                        CheckIns(false);
                      }}
                    />
                    <label className="form-check-label" htmlFor="insurance2">
                      No
                    </label>
                  </div>
                </div>
              </div>

              <button
                className="continue w-auto px-3"
                onClick={AddToCart}
                disabled={refresh}
              >
                {loading2 ? "Enviando" : "Comprar"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withAlert()(ProductDetail);
