import React, { useState } from "react";
import "../CSS/Tooltip.css"; // You need to create this CSS file for styling

const CustomTooltip = ({ text, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

    const handleMouseLeave = () => {
      setShowTooltip(false);
    };

  return (
    <div className="custom-tooltip-container">
      <div
        className="custom-tooltip"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      {showTooltip && <div className="tooltipp">{text}</div>}
    </div>
  );
};

export default CustomTooltip;
