import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageBanner from "../Components/PageBanner";
import axios from "axios";
import { withAlert } from "react-alert";

const Forget = ({ alert }) => {
  const [otpBox, setOTPBox] = useState(false);
  const [credentials, setCredentials] = useState({});
  const [change, setChange] = useState(false);
  const navigate = useNavigate();
  const code = useRef();

  const onEmailSubmit = async () => {
    try {
      await axios.get(
        process.env.REACT_APP_BASE_URL +
          `/api/auth/generateotp?email=${credentials.email.toLowerCase()}`
      );
      setOTPBox(true);
    } catch (err) {
      console.log(err?.response?.data);
      alert.show(<span className="small">{err?.response?.data}</span>, {
        type: "error",
      });
    }
  };

  const Verification = async () => {
    try {
      await axios.post(
        process.env.REACT_APP_BASE_URL +
          `/api/auth/verifyotp?code=${credentials.otp}`
      );
      setChange(true);
    } catch (err) {
      console.log(err);
      alert.show(
        <span className="small">
          Código ha expirado. Es necesario solicitar otro.!
        </span>,
        {
          type: "error",
        }
      );
    }
  };

  const ChangePassword = async () => {
    if (credentials.password === credentials.confirmpassword) {
      try {
        await axios.put(
          process.env.REACT_APP_BASE_URL + "/api/auth/resetPassword",
          {
            email: credentials.email,
            password: credentials.password,
          }
        );
        navigate("/login");
      } catch (error) {
        console.log(error?.response?.data);
      }
    } else {
      alert.show(<span className="small">¡Contraseñas no coinciden!</span>, {
        type: "error",
      });
    }
  };

  return (
    <div>
      <PageBanner title={"Recuperar Contraseña"} />
      <div className="mt-10 w-50 forgot_form py-3 mx-auto bg-white px-5 rounded shadow">
        {!change &&
          (!otpBox ? (
            <div>
              <label className="fw-bold">Email:</label>
              <input
                type={"email"}
                placeholder="Enviaremos un correo electrónico de reinicio!"
                className="form-control mt-1 rounded-0"
                onChange={(e) =>
                  setCredentials({
                    ...credentials,
                    email: e.target.value,
                    otp: "",
                  })
                }
              />
              <button
                className="continue w-auto px-3 mx-auto"
                onClick={onEmailSubmit}
              >
                Continuar
              </button>
            </div>
          ) : (
            <div>
              <label className="fw-bold">Ingresa código</label>
              <input
                value={credentials.otp}
                type={"text"}
                placeholder="Introduzca el código"
                className="form-control mt-1 rounded-0"
                onChange={(e) =>
                  setCredentials({ ...credentials, otp: e.target.value })
                }
              />
              <button
                className="continue w-auto px-3 mx-auto"
                onClick={Verification}
              >
                Verificar
              </button>
            </div>
          ))}

        {change && (
          <div>
            <label className="fw-bold">Nueva Contraseña</label>
            <input
              type={"password"}
              className="form-control mt-1 rounded-0"
              onChange={(e) =>
                setCredentials({ ...credentials, password: e.target.value })
              }
            />
            <label className="fw-bold mt-2">Confirma Contraseña</label>
            <input
              type={"password"}
              placeholder="Confirma Contraseña"
              className="form-control mt-1 rounded-0"
              onChange={(e) =>
                setCredentials({
                  ...credentials,
                  confirmpassword: e.target.value,
                })
              }
            />
            <button
              className="continue w-auto px-3 mx-auto"
              onClick={ChangePassword}
            >
              Confirmar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default withAlert()(Forget);
