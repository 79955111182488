import React, { useEffect, useState } from "react";
import PageBanner from "../Components/PageBanner";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import loader from "../assets/loading.gif";
import img from "../assets/tienda.jpg";
import specialP from "../assets/specialP.jpg";
import { useAuthContext } from "../Contexts/AuthContext";

const Shop = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const {userdata, user} = useAuthContext();
  const [modal, setModal] = useState(user ? Object.values(userdata).length < 12 : false)


  const Fetch = async () => {
    setLoading(true)
    const { data } = await axios.get(
      process.env.REACT_APP_BASE_URL + "/api/product/get",
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    );
    setData(data.data);
    setLoading(false)
  };

  useEffect(() => {
    Fetch();
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  return (
    <div>
      <PageBanner title={"Tienda"} img={img} />

      {modal && (
        <div
          className="modal_bg d-flex justify-content-center 
    align-items-center"
        >
          <div
            className="modal d-block"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog payment_dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="exampleModalLabel">
                    ¡Aviso! Completa tus datos personales.
                  </h1>
                  {/* <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setModal(false);
                    }}
                  ></button> */}
                </div>
                <div className="modal-body">
                  Necesitamos conocer tu Información de Contacto, Agencia donde deseas recibir en Venezuela, Domicilio para recogidas y otros datos para poder gestionar tus envíos correctamente.
                </div>
                <div className="modal-footer">
                  <p
                    className="continue w-auto fs-6 px-4 py-2 mt-0"
                    style={{ whiteSpace: "nowrap" }}
                    target="_blank"
                    type="button"
                    onClick={() => navigate(user ? `/profile?edit=true` : "/login")}
                  >
                    Completar
                  </p>
                  {/* <button
                    type="button"
                    className="btn btn-secondary ms-3"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setModal(false);
                    }}
                  >
                    Cancelar
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="canvas">
        <div className="mt-5 d-flex align-items-start flex-wrap product_row">
          {!loading ? data.map((elem) => {
            return (
              <div
                className="product_card me-4 mt-3"
                key={elem._id}
                type="button"
                onClick={() =>
                  navigate(
                    `/tienda/${elem._id
                    }`
                  )
                }
              >
                <img src={process.env.REACT_APP_BASE_URL + "/images/" + elem.images[0] + ".jpg"} alt="product-img" className="w-100"
                  height={300} />
                <p className="px-3 mt-2">{elem.name}</p>
                <p className="highlight mt-2 fw-bold px-3">€ {elem.price}</p>
              </div>
            );
          }) : <img src={loader} alt="loading..." className="loader mt-5" />}

          {!loading && <a href="https://postalven.com/contact" className="product_card mt-3">
           <img  src={specialP} className="h-100 w-100" />
           </a>}

          {/* <div className="product_card">
            <img
              src="https://www.tio.pl/foto/produkt_lista/10/iphone-12-green11.png"
              alt="product-img"
              className="w-100"
            />
            <p className="px-3">
              Apple iPhone 12 Pro Max 256GB (Single + eSIM)
            </p>
            <p className="highlight mt-2 fw-bold px-3">€ 650</p>
          </div>
          <div className="product_card">
            <img
              src="https://www.tio.pl/foto/produkt_lista/10/apple_iphone_13_3znj-gk21.jpg"
              alt="product-img"
              className="w-100"
            />
            <p className="px-3">Apple iPhone 13 512GB (Single + eSIM)</p>
            <p className="highlight mt-2 fw-bold px-3">€ 800</p>
          </div>
          <div className="product_card">
            <img
              src="https://www.tio.pl/foto/produkt_lista/10/iphone14propurple(2)3.jpg"
              alt="product-img"
              className="w-100"
            />
            <p className="px-3">Apple iPhone 14 Pro Max (Single + eSIM)</p>
            <p className="highlight mt-2 fw-bold px-3">€ 999</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Shop;
