import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { FaUserAlt } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import PageBanner from "../Components/PageBanner";
import { useAuthContext } from "../Contexts/AuthContext";

const Profile = () => {
  const location = useLocation();
  const { userdata, setuserData } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [showModal, setModal] = useState(false);
  const [open, setopen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [selectedA, setSelectedAgency] = useState(null);
  const [data, setData] = useState({});
  const navigate = useNavigate()

  const HandleChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const editParam = params.get('edit');
    setModal(editParam === 'true');
  }, [location]);


  useEffect(() => {
    const ScrollToTop = () => {
      window.scrollTo(0, 0);
    };
    ScrollToTop();
  }, []);

  let filterdata = Object.values(data).filter((elem) => elem.length > 0);

  const HandleEdit = async () => {
    // console.log(filterdata);
    if (
      (document.getElementById("phone2").value.length === 0 &&
        Object.values(filterdata).length === 16) ||
      (document.getElementById("phone2").value.length > 0 &&
        Object.values(filterdata).length === 17)
    ) {
      setLoading(true);
      await axios.put(
        process.env.REACT_APP_BASE_URL + `/api/auth/update/${userdata._id}`,
        {
          newUser: {
            ...userdata,
            ...data,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            token: Cookies.get("token"),
          },
        }
      );

      setLoading(false);
      navigate("/profile")
      window.location.reload();
      setModal(false);

      getUser();
    } else {
      alert("Completar todos los datos!");
    }
  };

  const getUser = async () => {
    const res = await axios.post(
      process.env.REACT_APP_BASE_URL + "/api/auth/user",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          token: Cookies.get("token"),
        },
      }
    );
    setuserData(res.data);
  };

  useEffect(() => {
    getUser();
    setData(userdata);
    setData((prev) => ({ ...prev, residence: "España" }));
  }, []);

  const countries2 = ["España", "Venezuela"];

  const countries = [
    "Alemania",
    "Bélgica",
    "Croacia",
    "Dinamarca",
    "Francia",
    "Irlanda",
    "Letonia",
    "Luxemburgo",
    "Países Bajos",
    "Suecia",
    "Bulgaria",
    "Eslovaquia",
    "Estonia",
    "Grecia",
    "Malta",
    "Polonia",
    "República Checa",
    "Austria",
    "Chipre",
    "Eslovenia",
    "Finlandia",
    "Hungría",
    "Italia",
    "Lituania",
    "Portugal",
    "Rumanía",
  ];

  const agency = [
    [
      "Gran Caracas",
      "ALTAMIRA: Av. San Juan Bosco de Altamira con 1era. Transversal, Res. Excelsior, PB, Local 2.",
      "ANDRÉS BELLO: Av. Andrés Bello, Colegio Nacional de Periodista, Local 4PB (Frente a Pollos Rivera) CP 1050",
      "ANTÍMANO/LA YAGUARA: Calle Real Boulevard de Antímano, entre Jefatura Civil y Plaza Bolívar, Local MRW.",
      "AV. CASANOVA: Inicio Av. Casanova, entre Calle San Antonio y Calle El Colegio, C.C. del Este, N. 21 (Diagonal a Hotel Kurssal, a media cuadra del Arabito de Sabana Grande).",
      "BARUTA: Calle Negro Primero cruce con Sucre, N. 15-04 (Al lado de Eurosweaters).",
      "BELLO MONTE: Colinas de Bello Monte, Av. Miguelangel, Edif. Oberon, Local E (Frente a Pastelería La Sabrina) CP 1060",
      "CAMPO CLARO: Av. Francisco de Miranda con Calle Guanche, Edif. Valencia I, PB (Al lado del Banco BOD) Los Dos Caminos.",
      "CATIA: Av. Sucre, C.C. Oeste, Nivel 3, Local 038.",
      "CARICUAO: Redoma de Ruiz Pineda, C.C. Caricuao Plaza, Piso 2, Local 127.",
      "CAPITOLIO: Av. Baralt, esquina de Conde a Piñango, Casona N. 11 (Al lado de Jefatura Catedral).",
      "CENTRO: Av. Universidad, esquina de Sociedad a Gradillas, Edif. Humbolt, PB, Local 4.",
      "CHUAO: Av. Ernesto Blon, C.C. Ciudad Tamanaco, PB, Local F.",
      "CHACAITO: Av. Pichincha, entre salida de Metro y Av. Tamanaco, C.C. Unico, PB, Local 5 y 6, Urb. El Rosal.",
      "CHACAO: Calle El Muñeco, entre Av. Francisco de Miranda y Av. Libertador, Edif. Guan, PB, Local 7.",
      "EL LLANITO: Av. Ppal del Llanito, Ferretería Casatrigo (Al frente de Supermercado Don Sancho).",
      "EL PARAISO: Entre A. Las Fuentes y Av. Washington, Calle 1, Quinta Francelis, PB, Local 1.",
      "EL HATILLO: Calle Matadero, C.C. Priscos, PB, Ofic. 904-4.",
      "EL CEMENTERIO: Av. Los Totumos entre Av. Louis Braillet y Floresta, Casa 20, Prado de María.",
      "EL JUNQUITO: C.C. Casa Junko, PB, Local A7, Km 18, Antigua Bomba.",
      "EL BOSQUE: Calle El Carmen, entre Av. El Empalme y Ppal. del Bosque, Quinta Torre La Vega, PB, Local 5 (A media cuadra de Fedecamaras).",
      "EL VALLE: Av. Intercomunal del Valle, C.C. El Palmar, Res. Don Pedro, Torre F, PB, Local MRW.",
      "FUERTE TIUNA: Av. Las Secuelas, Centro Simón Bolívar (Ferente al Stadium Jose Antonio Casanova).",
      "LAS ACACIAS: Av. General Medina Angarita, Edif. Res. Betania, PB, Local MRW, Urb. Las Acacias (Frente a Repuestos Pamaca).",
      "LA CANDELARIA: Perico a Puente Yanez, Edif. Serrano, PB, Local 3.",
      "LA CASTELLANA: Calle Urdaneta con Ppal. de La Castellana, Quinta Aurana, PB (A media cuadra del Restaurante La Estancia).",
      "LA FLORIDA: Urb. La Florida, Av. Los Chaguaramos, Quinta Coroli.",
      "LAS MERCEDES: Av. Veracruz, Edif. Matisco, PB (Diagonal a CONATEL).",
      "LA URBINA: Av. Ppal. de La Urbina, Edif. Apulia, PB, Local 4 (Cerca de Panadería Tulipán).",
      "LA TRINIDAD: Calle del Arenal, Quinta Laura, Urb. La Trinidad (A dos cuadras de Farmatodo, frente a Clínica Veterinaria Ávila).",
      "LAS MINAS: Av. Ppal. Las Minas de Baruta, C.C. Luna, Local 15 (Frente a parada de las camionetas al Silencio, a 50mts de la Policía Fátima).",
      "LEBRUN PETARE: Calle Los Naranjos, Edif. Lebrun, PB, Local 19 (Frente a Liceo Mariano Picón Salas).",
      "LECUNA: Calle Sur 3, Esquina Zamuro a Miseria, Edif. Inducentro, PB, Local 1 (A 20mts de la Av. Lecuna) Santa Rosalía.",
      "LOS CAOBOS: Av. Panamá con Av. Libertador, Quinta MRW (Detrás de Restaurante Gran Yein, subiendo por la Torre Polar).",
      "LOS CHAGUARAMOS: Av. Universidad, Edif. Maurica, PB, Local G (Frente a la Iglesia San Pedro).",
      "LOS CHORROS: Av. Ppal. La Carlota, entre Av. Francisco de Miranda y Rómulo Gallegos, Edif. Bertha, Local 05 (Frente a zona de carga del Millenium).",
      "LOS PALOS GRANDES: Av. Andrés Bello de Los Palos Grandes con 2da Transversal, Edif. Vista Hermosa, PB, Local C.",
      "LOS ROSALES: Av. Los Laureles con Av. Roosevelt, Res. Tiuna, Local E, PB (Frente a Plaza Tiuna Los Rosales).",
      "PALO VERDE: C.C. Palo Verde, Nivel 2, Local 11-2 (Frente a Farmacia Naturista, dentro de Centro de Comunicaciones Movilnet CANTV).",
      "PANTIN: Calle Pantin, entre Calle Samán y Los Angeles, Galpón MRW (Frente a Policía de Chacao).",
      "PLAZA ESTRELLA: Plaza Estrella a San Felipe, Res. Dorabel, PB, Local 3 (A media cuadra de Pasaje Anauco) San Bernardino.",
      "PLAZA LAS AMÉRICAS: C.C. Plaza Las Américas, Nivel Oro, Local 107-A, El Cafetal.",
      "SABANA GRANDE: Av. Orinoco, entre 2da Calle y Caroní, Quinta Ismenia, Local PB.",
      "SANTA FE: Urb. Santa Fe Norte, Av. José María Vargas, C.C. Santa Fe, Piso 2, Nivel Alameda, Local C2-43 (Al lado de Churromanía).",
      "SAN MARTÍN: Av. San Martín cruce con Av. Santander, C.C. Maracaibo, Nivel Mezanina, Local 12.",
      "SANTA MONICA: Av. Arturo Michelena cruce con Agustín Codazzi, Quinta Ceiba (Al lado de Farmacia Bama).",
      "SANTA SOFÍA: Av. Ppal. Santa Sofía, C.C. Santa Sofía, Local Z-3, PB.",
      "SEBUCAN: Av. Rómulo Gallegos entre Av. Ppal. de Sebucan y Av. Sucre de Los Dos Caminos, Res. Carlo, Local 2.",
    ],
    [
      "Amazonas",
      "PUERTO AYACUCHO: Av. Ppal. de Urb. Andrés Eloy Blanco, Edif. Bellmar, Local 1.",
    ],
    [
      "Anzoátegui",
      "BARCELONA: Av. Fuerzas Armadas, esquina Calle Eulalia Buroz, Edif. Azgan, PB, Local 02, Sector Centro.",
      "ANACO: Av. José Antonio Anzoátegui, C.C. Anaco Center, Local 79-C.",
      "EL TIGRE: Av. Francisco de Miranda con Calle 18 Sur, Edif. Los Geranios 2 y 3 (Frente a Panadería Siria).",
      "LAS GARZAS: Av. Jorge Rodríguez, Local MRW 1, Sector Las Garzas Lechería.",
      "PUERTO LA CRUZ: Av. Municipal, C.C. Estación de Servicio Sandoval, PB, Local 137-A, Urb. Pueblo Nuevo.",
      "PARIAGUAN: Calle Bolívar cruce con Colombia, Sector Centro (Frente a Cooperativa de Transporte Pariaguan Express, a pocos mts de Farmacia).",
      "PUERTO LA CRUZ CENTRO: Calle Ricauter, Edif. D Jorge, PB, Local 1.",
      "NUEVA BARCELONA: Prolong. Av. Fuerzas Armadas, C.C. Los Chaguaramos, Local 4.",
      "CLARINES: Av. Fernández Padilla, Mini C.C Los Cocos, PB, Local 5.",
      "LECHERÍA: Av. Ppal. de Lechería, C.C. La Concha, PB, Local 7 (Al lado de La Bodeguilla).",
      "EL TIGRE CENTRO: Carrera 2 Sur entre Calle 2 y 3, Edif. Hana, Pico 1, Local 1, Sector Pueblo Nuevo Sur.",
    ],
    [
      "Apure",
      "GUASDUALITO: Calle Cedeño entre Carrera Arismendi y General Salón, Casa S/N, Sector Centro Guasdualito.",
      "SAN FERNANDO CENTRO: Av. Caracas, Centro Empresarial Genesis, PB, Local 1 (A 50 mts de Paseo Libertador).",
    ],
    [
      "Aragua",
      "MARACAY ZONA INDUSTRIAL: Av. Bermudez, C.C. Maracay Plaza, PB, Local PB-82F.",
      "LA VICTORIA: Av. Victoria, PB, Local 2 (Frente al Estadio Francisco de Miranda).",
      "MARACAY 5 DE JULIO: Calle 5 de Julio entre Páez y Miranda, Edif. Santimone, PB, Local 3 (Diagonal a IMGEVE).",
      "TURMERO: Calle Mariño cruce con Calle Peñalver, C.C. Mariño Plaza, PB, Local 2.",
      "VILLA DE CURA: Calle Urdaneta Norte, Sector Centro.",
      "LAS TEJERÍAS: Calle Miranda, Local b-44 A 2, Casco Central (A dos cuadras de la Plaza Bolívar, Municipio Santos Michelena).",
      "SAN SEBASTIAN DE LOS REYES: Calle Bolívar, Local 18-2, Sector Centro.",
      "MARACAY LA DEMOCRACIA: Av. Ayacucho Norte, N. 83, Barrio La Democracia.",
      "TURMERO ZONA INDUSTRIAL: Av. Intercomunal Maracay Turmero, C.C. Coche Aragua, Local 30 y 82, Sector La Morita.",
      "LA ENCRUCIJADA: Av. Ppal., C.C. Los Laureles, PB, Local 12, Sector La Encrucijada de Turmero.",
      "MARACAY SANTA ROSA: Calle Carabobo, N. 75-A, Sector Santa Rosa.",
      "MARACAY LA ROMANA: Av. Bolívar Oeste, N. 165, Edif. Guey 2, Calle Río Guey, Sector La Romana.",
    ],
    [
      "Barinas",
      "BARINAS: Calle Cedeño, C.C. Giamma, PB, Local 6, Sector Centro.",
      "SOCOPÓ: Calle 1 entre Carrera 6 y 7, Sector Barrio El Carmen (Diagonal a la Estrella Oriental).",
      "BARINAS ZONA INDUSTRIAL: Av. Elías Cordero, Detrás del Terminal de Pasajeros, Edif. Hotel Rey de Reyes, Local 03 (Diagonal a Ferretería El Mecatazo).",
      "SANTA BARBARA DE BARINAS: Calle 12 entre Carreras 0 y 00, Sector El Progreso (A 2 cuadras y media de CORPOELEC).",
      "BARINAS SABANETA: Calle 1 entre Bayon y Obispo, C.C. Tricolor, N. 2, Sector 9 de Diciembre de Sabaneta.",
      "LAS TEJERÍAS: Calle Miranda, Local b-44 A 2, Casco Central (A dos cuadras de la Plaza Bolívar, Municipio Santos Michelena).",
      "ALTO BARINAS: Av. Francia entre Av. Pie de Monte y Táchira, Quinta Doña Ernestina, N. 135, Alto Barinas Norte.",
      "FORUM: Av. 23 de Enero con Av. Guaicaipuro, C.C. Forum, Local 52.",
      "BARINAS 23 DE ENERO: Av. 23 de Enero, Edif. La Fortana, Local 3 (A 50 mts de la Residencia de la Gobernación).",
    ],
    [
      "Bolívar",
      "CIUDAD BOLÍVAR: Av. República, Edif. Franco, PB, Locales 1 y 2 (Al lado de Banesco).",
      "ALTA VISTA: Av. Puerto Ordaz, C.C: Babilonia Mall, PB, Local PB-30, Sector Alta Vista (Bajando por el Banco Provincial).",
      "SANTA ELENA DE UAIREN: Calle Ikabarú, Troncal 10, Casco Central, Local S/N (A 100 mts del Hospital Rosario Vera Zurita.",
      "UNARE: Calle Neveri, Galpón MRW (Frente al Aeropuerto a 500 mt de los Bomberos al lado de Metalmeg).",
      "CIUDAD BOLÍVAR ZONA INDUSTRIAL: Av. Nueva Granada, Edif. Gran Sabana, PB, Local 1.",
      "LAS AMÉRICAS: Av. Brasil, Urb. Villa Brasil, Manzana 142, Local N. 2 (A 200 mt del Abasto La Española, Sector Villa Antillana).",
      "PLAZA ATLÁNTICO: C.C. Plaza Atlántico Mall, final Av. Atlántico, Local PB-12.",
    ],
    [
      "Carabobo",
      "VALENCIA CENTRO: Av. Miranda, Local 118-31 MRW (Detrás de IMGEVE Av. Bolívar Norte).",
      "VALENCIA BIG LOW: Calle 97, C.C. Ciudad Valencia, PB, Local B4. Urb. Zona Industrial Castillito.",
      "GUACARA: Av. Carabobo con Calle Vargas y Lovera, C.C. Carabobo, PB, Local 15.",
      "ALIANZA MALL: Carretera Nacional Guacara, Urb. Ciudad Alianza, C.C. Central Guacara, Local 27 (Frente a Supermercado Central Madeirense).",
      "LOS GUAYOS ZONA INDUSTRIAL: Carretera Nacional de Los Guayos con Av. 67, Zona Industrial Municipal Norte, Edif. Tacarigua, Local 2.",
      "PUERTO CABELLO: Calle Rondon, Edif. El Naranjal, Local 2 (Frente a Templo El Refugio, al lado de la Clínica San Agustín).",
      "MORÓN: Av. Yaracuy, N. 52 (Frente a Banco Banesco, al lado de Banco bod).",
      "FLOR AMARILLO: Av. Las Industrias cruce con Av. Ppal., Urb. Parque Valencia, C.C: Mega Mercado, PB, Local 8C1.",
      "EL TRIGAL: Av. Ppal. de Mañongo, C.C. Patio Trigal, PB, Local 208, Urb Trigal Norte.",
      "NAGUANAGUA: Av. 96-B, C.C. Cristal, PB, Local B-06 (A dos locales de Banco Bicentenario).",
      "MARIARA: Av. Bolívar, Local N. 54, Sector Guamacho, Barrio El Carmen (A 100 mts de Farmacia Coromoto).",
      "SAN JOAQUÍN: Av. Carabobo, Calle Miranda, C.C. Eurocenter, PB, Local 13.",
      "VALENCIA SUR: Autopista Valencia Campo Carabobo, C.C. El Prado, PB, Local B6, Los Caobos.",
      "BEJUMA: Av. Carabobo entre Calle Piar y Calle Valencia, Local S/N.",
      "LA CANDELARIA: Av. Aranzazu, Edif. Vorma, PB, Local PB-3, La Candelaria.",
      "AV. LAS FERIAS: Av. Bolívar Sur (Las Ferias) con Calle Silva N. 93-23, C.C. Metro Lara, Local C 19.",
      "VALENCIA ZONA INDUSTRIAL: Av. Prolongación Michelena, C.C. Mycra, Local 10, Zona Industrial Valencia.",
      "LA ISABELICA: Av. 04, Sector 10, Vereda 14, Local 01, Urb. La Isabelica.",
      "GÜIGÜE: Av. Miranda con Av. Michelena, Local 8-54, Sector Güigüe.",
      "EL PARRAL: Las 4 Avenidas, Centro Profesional Ceravica, PB, Local 2, Urb. El Parral.",
      "SAN DIEGO: Av. Don Julio Centeno, C.C. Metro Plaza, PB, Local 33.",
      "MORRO II: Av. 73 vía de servicio, Casa N. 656, Local 656-B (Al lado de McDonald’s a 50 mts de C.C. Fin de Siglo).",
      "VALENCIA NORTE: Av. Bolívar Norte, Sector Majay, Local N. 151-54 (Frente a Torre Principal Banco BNC).",
      "SAN DIEGO: Av. Don Julio Centeno, Calle 103, C.C. Prati, PB, Local L-4, Urb. Terrazas de Castillito (Frente a Mar de la Cerámica).",
      "VALENCIA GOBERNACIÓN: Calle Paez entre Montes de Oca y Carabobo, C.C. Papin, PB, Local 1, Centro (Cerca del Capitolio).",
      "AV. LARA: Av. Lara con Calle Uslar, Local 87-107 (Frente a Molinari Caccia Guerra).",
      "PARAPARAL: Urb. Paraparal, Av. Ppal. C.C. Cristal, Local 2 (Al lado de Pasteleria Capuchino).",
    ],
    [
      "Cojedes",
      "SAN CARLOS: Calle Sucre entre Zamora y Libertad, Local 8-33.",
      "TINAQUILLO: Av. Madariaga entre Calle Cedeño y Calle Negro Primero.",
    ],
    [
      "Delta Amacuro",
      "TUCUPITA: Calle Dalla Costa N. 22, Local 1, Edif. Ciarcia (Frente a Plaza Bolívar).",
    ],
    [
      "Falcón",
      "CORO: Calle Zamora esquina Callejón Las Flores e Iturbe, Local S/N, Sector Chimpire",
      "TUCACAS: Av. Libertador de Tucacas, Local 1 (Diagonal a Hotel La Suerte).",
      "CAJA DE AGUA: Calle Manuelita Sáenz entre Calle Comercio y Providencia, Local 1, Sector Caja de Agua (Diagonal a Iglesia Los Mormones).",
      "PUERTA MARAVEN: Calle San Román entre Av. General Pelayo y Av. Ollarvides (Diagonal a Rías Altas).",
      "EL CASTILLO: Av. Manaure esquina con Calle Monzon, C.C. El Castillo Don Leoncio, PB, Local 10.",
      "PUNTO FIJO: Calle Monagas entre Garcés y Zamora, Edif. Lucriscar, PB.",
    ],
    [
      "Guárico",
      "SAN JUAN DE LOS MORROS: Calle El Carmen, Edif. Rauseo, Local 2 (Bajando por la Gobernación, diagonal a CANTV).",
      "CALABOZO: Carrera 12 entre Calle 4 y 5, C.C. Coromoto (Frente a Plaza Bolívar).",
      "VALLE DE LA PASCUA: Calle González Padrón, C.C. Star Center, Local B-10.",
      "ZARAZA: Calle Bolívar entre Ayacucho y Sucre, Local MRW, PB (A 100 mts del SAIME).",
      "TUCUPIDO: Calle Salom, N. 24, Local MRW (Diagonal a Alcaldía entre San Pablo y Zaraza).",
      "ALTAGRACIA DE ORITUCO: Calle Hurtado Ascanio cruce con Vuelvan Cara, Edif. Residencias La Paz, PB, Local 2, Sector Las Brisas del Este.",
    ],
    [
      "Lara",
      "BARQUISIMETO CENTRO: Av. Venezuela entre Calle 39 y 40, N. 39-55, Sentido Oeste-Este.",
      "CANAIMA: Calle 55 entre Av. Pedro León Torres y Carrera 19, C.C. Canaima, Local F-02. Zona Este.",
      "ANDRÉS BELLO: Av. Andrés Bello entre Carrera 22 y 23, Edif. Pulca 2, Locales 2 y 3.",
      "BARQUISIMETO OESTE: Av. Florencio Jimenez, C.C. Arcoiris, Local 5, PB (Frente a Decanato de la UCLA).",
      "CARORA: Av. Francisco de Miranda, Calle 15A y 1, Casa N. 15, Sector Egidio Montesino (A 50 mts de Plaza Chio Zubillaga).",
      "BARQUISIMETO ESTE: Calle 15 entre Carrera 20 y 21, Local 5 (Diagonal a Clínica San Francisco).",
      "AV. MORAN: Carrera 21 entre Av. Moran y Calle 8, C.C. Plaza Sevilla, Local 28 y 29.",
      "CABUDARE: Av. Santa Bárbara entre Calle Guillermo Alvizu y La Cruz, C.C. Santa Barbara, Local 2 (Detras de Ferreteria Tabure).",
      "QUIBOR: Calle 8 entre Av. 8 y 9 (A 200 mts de Supermercado La Palma).",
      "VALLE LINDO: Autopista vía Duaca, KM 10, Sector Sabana Grande (Al lado de Farmacia San Ignacio).",
      "BARQUISIMETO NUEVA SEGOVIA: Calle 3 entre Carrera 1 y Av. Lara, Casa N. AL-99, Urb. Nueva Segovia.",
      "PATARATA: Av. Libertador entre Av. Argemiro Bracamonte y Av. López Contreras, Sector Patarata, Centro Recreacional Parque Jardín, Local 4.",
      "BABILOM: Calle 19 con Av. Libertador, Zona Industrial I, C.C. Libertador, Local 13-B.",
      "EL RECREO: Av. Libertador, C.C. El Rosario, Local 5 (Frente al IPASME).",
      "CABUDARE SUR: Av. El Placer, Local Trigalpa N. 7, Urb. El Trigal, Los Rastrojos.",
      "AV. VENEZUELA: Av. Venezuela con Calle 21, Edif. Lazio, Local 2.",
    ],
    [
      "Mérida",
      "MÉRIDA CUBO ROJO: Av. Gonzalo Picón con Viaducto Miranda, C.C. Cubo Rojo, PB, Local 2,4,6,7,8, Sector Glorias Patrias",
      "MÉRIDA PASEO DE LA FERIA: Av. Don Tulio Febres Cordero con Calle 29, Edif. El Paseo, PB, Local único (Detrás del Edif. Administrativo de la ULA).",
      "EL VIGÍA: Calle 3 con Av. Don Pepe Rojas, Local Galpón S/N, Barrio Bolívar.",
      "TOVAR: Carrera 4ta, C.C. El Llano, PB, Local 4, Sector El Llano Tovar.",
      "EJIDO: Av. Fernández Peña, Casa PB, N. 133-C, Sector Montalbán (A 100 mts de Diario Frontera)",
      "MÉRIDA MILLA: Av. 5 con esquina Calle 16, Local N. 14-106, Sector Belén.",
      "MÉRIDA LOS PROCERES: Av. Los Proceres, Calle La Orquídea, Minicentro Comercial Don Luis, Local 2B MRW.",
      "TUCANIZON: Carretera Panamericana, Sector El Carmen, Galpón 1 (Al lado de Estacion de Servicio El Indio).",
    ],
    [
      "Miranda",
      "CHARALLAVE: Calle 9, Av. Jose Gregorio Hernández con Av. Bolívar, Edif. Los Samanes, N. 3, Sector Casco Central.",
      "OCUMARE DEL TUY: Av. Miranda con Calle Toribio Mota, Edif. Televisa, PB (Frente a parada de Parosca).",
      "GUARENAS: Calle 19 de Abril entre Calle Comercio y Andrés Bello, Local 1 (A media cuadra de Plaza La Candelaria).",
      "GUATIRE: Calle Zamora, N. 47, Local PB-1, Edif. Quinta Arelis.",
      "SAN ANTONIO DE LOS ALTOS: Carretera Panamericana, KM 16, C.C. La Casona II, Piso 1, Local 2-17 (Al lado de Cinex).",
      "HIGUEROTE: Calle el Río, C.C. Martí Plaza, Local 1.",
      "RÍO CHICO: Calle Comercio, Local MRW (Al lado de Farmacia Las Mercedes) Río Chico.",
      "SANTA TERESA DEL TUY: Calle Ayacucho, Edif. Don Guillermo, Piso 1, Ofic. 4, Zona Centro.",
      "CÚA: Urb. Jardines de Santa Rosa, C.C. El Colonial, Local 26-A.",
      "GUATIRE OASIS: Av. Intercomunal Guarenas Guatire, C.C. Oasis Center, PB, Local 15 (Frente a Banco Bicentenario).",
    ],
    [
      "Monagas",
      "PUNTA DE MATA: Calle 5 de Julio cruce con Nuev, Local WRW, (Detrás del Banco Caroní).",
      "MATURÍN CENTRO: Calle 14 (Antigua Av. Rojas) cruce con Calle 10 (Antigua Barreto), Edif. Bravo, PB, Local 1 (Diagonal a la CANTV).",
      "TEMBLADOR: Calle Bolívar, N. 70-A, Sector La Plaza (Al lado de Carnicería El Brahmon).",
      "MATURÍN NORTE: Av. Ppal. Vía Viboral, C.C. Virgen del Valle, Local 28-A, PB, Sector Tipuro, Parroquia Boquerón.",
      "MATURÍN ZONA INDUSTRIAL: Calle Principal de La Cruz de La Paloma cruce con La Macarenas, PB, Local 5.",
      "MATURÍN AV. RAÚL LEONI: Av. Raul Leoni entre Carrera 3 (Antigua Av. Rivas) y Carrera 4 (Antigua Prolongación), Frente al Polideportivo.",
      "MATURÍN LA FLORESTA: Carrera 1, N. 4, C.C. La Redoma, PB, Local 5, Sector Brisas del Aeropuerto (Diagonal al SENIAT).",
      "MATURÍN PLAZA EL INDIO: Av. Bicentenario, Edif. Zamora, PB (Al lado de Línea de Taxi Lo Mejor de lo Mejor).",
    ],
    [
      "Nueva Esparta",
      "JUAN GRIEGO: Calle Guevara, N. 12 B entre Calle La Marina y Marcano (Diagonal a Comercial Juan Griego).",
      "EL ESPINAL: Av. Juan Bautista Arismendi, Sector La Encrucijada del Espinal vía San Juan Bautista (A 100 mts de la Regional El Espinal).",
      "JOVITO VILLALBA: Av. Jovito Villalba, Local Estación de Servicio Maneiro, N. 9493, Urb. San Lorenzo, Pampatar.",
      "NUEVA CADIZ: Av. Juan Bautista Arismendi, Edif. Ultramar, Local 2, Sector Los Cocos (Diagonal a Citroen).",
      "PORLAMAR CENTRO: Calle Velázquez con esquina Fajardo, Casa S/N, Sector Centro.",
      "VILLA ROSA: Av. Juan Bautista Arismendi, Galpón Seven-Art, PB, Sector San Antonio Norte.",
    ],
    [
      "Portuguesa",
      "GUANARE: Av. Jose María Vargas, C.C. Revica, Galpón N. 4, Sector El Cambio.",
      "ACARIGUA: Calle 22 entre Av. Libertador y Alianza (Diagonal a Panadería Trebol).",
      "CENTRO LOS LLANOS: Calle 31 con Av. 28, C.C. Los Llanos, PB, Local 5.",
    ],
    [
      "Sucre",
      "CUMANÁ: Calle Marino, Edif. Tunimiquire, Local 1.",
      "CARUPANO: Av. Universitaria, Edif. Prosein, Piso Mezanina, Local B, Sector Los Molinos.",
      "CUMANÁ URDANETA: Calle 4ta Transversal Av. Gran Mariscal cruce con Urdaneta, Sector Santa Rosa, Edif. Ceu, PB, Local 2.",
    ],
    [
      "Táchira",
      "BARRIO OBRERO CARRERA 20: Barrio Obrero, Calle 10, Carrera 20, Casa N. 9-108, Local 6 (Frente a Licorería 9 con 19).",
      "SAN CRISTOBAL CONCORDIA: Calle 4bis cruce con Carrera 6 y 7, Edif. Ramírez, PB, Local 1 (Dtrás del Diario La Nación).",
      "SAN ANTONIO DEL TÁCHIRA: Av. Venezuela con Calle 7, Edif. Real, Local 7-09, Sector Centro (A tres cuadras de la Aduana Principal).",
      "LA FRÍA: Carrera 11 entre Calle 5 y 6, N. 4-49, Municipio García de Hevia, La Fría.",
      "LA GRITA: Av. Francisco de Cáceres con calle 6, Casa N. 7-9, La Grita (Diagonal al Colegio Santa Rosa de Lima).",
      "RUBIO: C.C. Venezia, Locales 6 y 7 (Frente a Plaza Bolívar).",
      "EL PIÑAL: Calle 1, Vía La Morita, Zona Comercial El Mirador, El Piñal.",
      "TARIBA: Calle 8 con Carrera 8, Casa N. 7-87, Local 2 (A dos cuadras del Hospital San Antonio)",
      "BARRIO OBRERO: Carrera 22 esquina Pasaje Pirineos, N. 21-97, Sector Barrio Obrero.",
      "UREÑA: Calle 5 con esquina Carrera 6, Edif. Sofi, PB, Local 103, 104, 105, 106 y 107, Barrio La Guajira.",
      "SAN CRISTÓBAL CENTRO: 7ma Av. entre Calle 5 y 6, C.C. Partenón, PB, Local 3 (Diagonal a Torre Unión, a cuadra y media de Plaza Bolívar).",
      "PARAMILLO: Av. Los Agustinos esquina Calle 4, Local 3-70, Urb. El Lobo, Sector Paramillo.",
      "SAN CRISTÓBAL CENTRO: Carrera 5 esquina Calle 7, N. 4-79, San Juan de Colón.",
    ],
    [
      "Trujillo",
      "TRUJILLO: Calle Regularización, Sector Centro, C.C. Geminis Center, PB, Local 3 (Frente a Plaza Bolívar).",
      "VALERA: Calle 5 entre Av. Bolívar y 9, Edif. Profesional Don Pepe, PB, Local MRW.",
      "CARACHE: Av. Ppal., Casa S/N, Sector Palo Negro.",
      "BOCONÓ: Av. Miranda cruce con Andrés Bello, Local 2, Sector Centro.",
      "VALERA LOS LIMONCITOS: Av. Bolívar esquina con Calle 22, Edif. Residencias Trinitarias II, PB, Local B, Sector Los Limoncitos (Frente a McDonald’s).",
      "VALERA CENTRO: Av. 12 con Calle 08 y 09, Edif. Beatriz, PB, Local 01, Sector Centro.",
      "SABANA DE MENDOZA: Av. Las Flores entre Calle Piar y Comercio, Local 4.",
    ],
    [
      "La Guaira",
      "CATIA LA MAR: Calle 13 Atlantida cruce con Pemysa (Frente a Oficina CORPOELEC).",
    ],
    [
      "Yaracuy",
      "SAN FELIPE: Calle 12 entre Av. 8 y 9, Edif. Don Jorge, PB, Local 3, Sector Caja de Agua.",
      "NIRGUA: Av. 5 entre Calle 4 y 5, Edif. Murcia, Local 3, Sector Plaza Sucre (Diagonal al Banco Provincial).",
      "YARITAGUA: Calle 17 entre Carrera 7 y 8, Local S/N (Diagonal al Registro Civil).",
    ],
    [
      "Zulia",
      "CIRCUNVALACIÓN 2: Parroquia Cecilio Acosta, Municipio Maracaibo, Av. 58, Circunvalación N. 2, Edif. Casa SAAS, PB, Local 98E-164 entre calle 98E y 99, Barrio Andrés Eloy Blanco.",
      "LOS OLIVOS: Av. 28 La Limpia con Av. 69, Local 69B-09, Sector Los Aceitunos (Al lado de la E/S Los Aceitunos).",
      "MARACAIBO NORTE: Av. Paul Moreno, antigua Fuerzas Armadas con calle 20, Edif. Milenium, Casa SAAS S/N.",
      "MARACAIBO INDIO MARA: Calle 78, Dr. Portillo entre Av. 17 y 18, N. 17-35 CC Don Julio (Diagonal al Banco Provincial), Sector El Paraíso.",
      "CIUDAD OJEDA: Av. Intercomunal, Local MRW, Sector Las Morochas (Frente a la Bomba Central).",
      "MANZANILLO: Calle 10 La Unión con Av. 24, C.C. B & B, PB, Local 2, Sector Manzanillo.",
      "COROMOTO: Urb. Coromoto, Av. 40, C.C. Villa Mall, PB, N. 161-44, Local 4 (Frente a la emisora Alegría Stereo).",
      "MACHIQUES: Calle Unión entre Av. Udon Pérez y Valle Frio, Casa S/N, Sector Valle Frio.",
      "CABIMAS: Av Intercomunal, Esquina Cumaná, Edif. Intercumaná, PB, Local 02.",
      "LA CHINITA: Av. 12 cruce con 93 Padilla, Edif. Padilla, Local 33, Sector Casco Central (Al lado de Centro de Literatura Cristiana).",
      "MARACAIBO IRAMA: Av. 5 con Calle E, Local E-71, Sector 18 de Octubre (A pocos mts de Frenos Irazuca) Monte Claro.",
      "SANTA BARBARA DEL ZULIA: Av. 8, Casa N. 5-127, Sector Bolívar, Santa Bárbara.",
      "EL VENADO: Av. Independencia, C.C. San Antonio (Los Duartes), Local 01, Mene Grande, Municipio Baralt.",
      "CIUDAD OJEDA CENTRO: Calle Faria esquina Calle Lara, C.C. Ted, PB, Local 03, Sector Casco Central.",
      "TIA JUANA: Av. Intercomunal de Tia Juana, Sector Ezequiel Zamora, Local 1 (Frente a Prodata Wire-Line).",
      "MARACAIBO LA LAGO: Av. 3E entre Calle 72 y 73, Edif. Asociación Zuliana de Ciegos, PB, Local 3, Sector La Lago Maracaibo.",
      "CAJA SECA: Nueva Bolivia Mérida, Ctra. Panamericana, Sector La Chertoza, C.C. Stilo, PB, Local 2.",
      "CURVA DE MOLINA: Calle 79, N. 92-58 (Frente al Modulo Libertador, al lado de la Ferretería Ranye).",
    ],
  ];

  const handleDropdownClick = (item) => {
    setSelected(item);
    setopen(true);
  };

  if (!userdata) return <Navigate to={"/"} />;

  return (
    <div className="ProfileSection">
      <input type="hidden" id="inp" />
      <PageBanner title="Mi Cuenta" />
      <div className="px-5 profile_box mx-auto py-5 mt-10 bg-white rounded position-relative">
        <div className="d-flex align-items-center w-100 mt-3">
          <Link
            to={"/resetpassword"}
            style={{ position: "absolute", right: "5%", top: "3.5%" }}
            className="nav-link highlight"
          >
            Cambiar mi contraseña
          </Link>
          <div className="upload-btn-wrapper mt-4">
            <div className="avatar d-flex justify-content-center align-items-center">
              <FaUserAlt
                size={window.matchMedia("(max-width: 550px)").matches ? 40 : 70}
              />
            </div>
          </div>
          <div className="d-flex align-items-center ms-5 prof_info mt-4">
            <h1 className="mb-0">{userdata.name + " " + userdata.surname}</h1>
          </div>
        </div>

        <div className="d-flex justify-content-between profile-flex">
          <div className="personal_det w-50 mt-5">
            <p className="fs-4 highlight fw-bold d-flex align-items-center">
              Datos Personales
              <MdModeEdit
                className="ms-2 text-muted fs-4"
                type="button"
                onClick={() => setModal(true)}
              />{" "}
              <button
                className="continue w-auto ms-2 px-2 py-1 mt-0"
                onClick={() => setModal(true)}
              >
                Actualiza
              </button>
            </p>
            <p className="mt-1 fs-5 font2">Email : {userdata.email}</p>
            <p className="mt-1 fs-5 font2">Teléfono 1: {userdata.phone}</p>
            <p className="mt-1 fs-5 font2">Teléfono 2: {userdata.phone2}</p>
            <p className="mt-1 fs-5 font2">Tipo de Id : {userdata?.doctype}</p>
            <p className="mt-1 fs-5 font2">
              Número de Documento : {userdata?.docId}
            </p>
            <p className="mt-1 fs-5 font2">
              País de Residencia : {userdata.residence}
            </p>
            <p className="mt-1 fs-5 font2">
              Código Postal : {userdata.postalcode}
            </p>
            <p className="mt-1 fs-5 font2">
              Agencia de Entrega Predeterminada:{" "}
              {userdata?.agencyName?.toUpperCase()},{" "}
              {userdata.agency?.toUpperCase()}
            </p>
          </div>

          <div className="personal_det w-50 mt-5">
            <p className="fs-4 highlight fw-bold">
              Dirección mi Buzón Postalven
            </p>
            <p className="fs-5 mt-2 font2">
              Nombre: Postalven {userdata?.uid}
              <br />
              Dirección: Av. Camino de lo Cortao 6, Nave 30
              <br />
              Cód.Postal: 28703
              <br />
              San Sebastian de los Reyes, Madrid, España
              <br />
              Tel. +34611591919
              <br />
            </p>
          </div>
        </div>

        <p className="text-center mt-4 text-danger">
          Por favor mantenga su información personal actualizada en todo
          momento.{" "}
        </p>
      </div>

      {showModal && (
        <div className="modal profile_modal_bg">
          <div className="modal-dialog mx-auto profile_modal">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Completa tus datos
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <label>Nombres:</label>
                <input
                  type={"text"}
                  className="form-control rounded-0 mt-1"
                  id="name"
                  onChange={(e) => HandleChange(e)}
                  value={data.name}
                  // disabled
                />
                <label>Apellidos:</label>
                <input
                  type={"text"}
                  className="form-control rounded-0 mt-1"
                  id="surname"
                  onChange={(e) => HandleChange(e)}
                  value={data.surname}
                  // disabled
                />
                <label className="mt-2">Móvil/Celular:</label>
                <input
                  type={"text"}
                  className="form-control rounded-0 mt-1"
                  id="phone"
                  onChange={(e) => HandleChange(e)}
                  value={data.phone}
                />
                <label className="mt-2">Teléfono adicional:</label>
                <input
                  type={"text"}
                  className="form-control rounded-0 mt-1"
                  id="phone2"
                  onChange={(e) => HandleChange(e)}
                  value={data.phone2}
                />
                <div className="dropdown my-2">
                  <label className="mt-2">Tipo de Id:</label>

                  <button
                    className="form-control rounded-0 border text-start"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {data.doctype ? data.doctype : "Tipo de Id:"}
                  </button>
                  <ul className="dropdown-menu w-100">
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData({ ...data, doctype: e.target.innerHTML })
                      }
                    >
                      DNI
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData({ ...data, doctype: e.target.innerHTML })
                      }
                    >
                      NIE
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData({ ...data, doctype: e.target.innerHTML })
                      }
                    >
                      Pasaporte
                    </li>
                    <li
                      className="dropdown-item"
                      type="button"
                      onClick={(e) =>
                        setData({ ...data, doctype: e.target.innerHTML })
                      }
                    >
                      Cédula
                    </li>
                  </ul>
                </div>

                <label className="mt-2">Número de Documento:</label>
                <input
                  type={"text"}
                  className="form-control rounded-0 mt-1"
                  id="docId"
                  onChange={(e) => HandleChange(e)}
                  value={data?.docId}
                />
                <label className="mt-2">Dirección :</label>
                <input
                  type={"text"}
                  className="form-control rounded-0 mt-1"
                  id="direction"
                  onChange={(e) => HandleChange(e)}
                  value={data?.direction}
                />
                <label className="mt-2">Número:</label>
                <input
                  type={"text"}
                  className="form-control rounded-0 mt-1"
                  id="homenumber"
                  onChange={(e) => HandleChange(e)}
                  value={data?.homenumber}
                />

                <label className="mt-2">Pueblo, Ciudad, Provincia:</label>
                <input
                  type={"text"}
                  className="form-control rounded-0 mt-1"
                  id="homevia"
                  onChange={(e) => HandleChange(e)}
                  value={data?.homevia}
                />
                <label className="mt-2">Código Postal:</label>
                <input
                  type={"text"}
                  className="form-control rounded-0 mt-1"
                  id="postalcode"
                  onChange={(e) => HandleChange(e)}
                  value={data?.postalcode}
                />

                <label className="mt-2">País:</label>
                <div className="dropdown mt-1">
                  <button
                    className="form-control field rounded-0 mt-2 text-start"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {data.residence ? data.residence : ""}
                  </button>
                  <ul
                    className="dropdown-menu w-100"
                    style={{ height: "200px", overflowY: "scroll" }}
                  >
                    {countries2.map((elem, i) => {
                      return (
                        <li
                          key={i}
                          className={"dropdown-item"}
                          type="button"
                          onClick={(e) => setData({ ...data, residence: elem })}
                        >
                          {elem}
                        </li>
                      );
                    })}

                    {countries.sort().map((elem, i) => {
                      return (
                        <li
                          key={i}
                          className={"dropdown-item"}
                          type="button"
                          onClick={(e) => setData({ ...data, residence: elem })}
                        >
                          {elem}
                        </li>
                      );
                    })}
                  </ul>
                </div>

                
                <label className="mt-2">
                  Agencia:{" "}
                  <small>
                    <span className="fw-bold">
                      {selectedA ? selectedA : data.agencyName}{" "}
                    </span>{" "}
                    {data?.agency}
                  </small>
                </label>

                <div className="dropdown mt-1">
                  <button
                    className="form-control field rounded-0 mt-2 text-start"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {data.agencyName ? data.agencyName : "Agencia de Entrega"}
                  </button>
                  <ul
                    className="dropdown-menu w-100"
                    style={{ height: "200px", overflowY: "scroll" }}
                  >
                    {agency.map((item, index) => (
                      <li
                        key={index}
                        className="dropdown-item"
                        type="button"
                        onClick={(e) => handleDropdownClick(e.target.innerHTML)}
                      >
                        {item[0]}
                      </li>
                    ))}
                  </ul>
                </div>

                {open && (
                  <div className="sublist-container">
                    <h3>Selecciona Agencia:</h3>
                    <ul className="mt-2">
                      {agency
                        .find((item) => item[0] === selected)
                        .slice(1)
                        .map((subItem, index) => (
                          <>
                            <li
                              className={`${
                                subItem === data.agency &&
                                "bg-success text-white"
                              }  fw-bold bg-gray p-2`}
                              type="button"
                              key={index}
                              onClick={() => {
                                setSelectedAgency(selected);
                                // setData((prev) => ({
                                //   ...prev,
                                //   agency: subItem,
                                // }));
                                setData({
                                  ...data,
                                  agency: subItem,
                                  agencyName: selected,
                                });
                                setopen(false);
                              }}
                            >
                              {subItem}
                            </li>
                            <br />
                          </>
                        ))}
                    </ul>
                    <button
                      onClick={() => {
                        setopen(false);
                        setSelected(null);
                      }}
                      className="btn btn-danger"
                    >
                      Atrás
                    </button>
                  </div>
                )}
                
              </div>

              

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary px-2 py-1"
                  data-bs-dismiss="modal"
                  onClick={() => setModal(false)}
                >
                  Cerrar
                </button>
                <button
                  type="button"
                  className="continue w-auto ms-2 px-2 py-1 mt-0"
                  onClick={HandleEdit}
                >
                  {loading ? "Enviando" : "Actualizar"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
